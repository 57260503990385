import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./mystyles.css";
import QualityIcon from "./icons/QualityIcon";
import DedicationIcon from "./icons/DedicationIcon";
import PassionIcon from "./icons/PassionIcon";
import QualityCardIcon from "./icons/QualityCardIcon";
import DedicationCardIcon from "./icons/DedicationCardIcon";
import PassionCardIcon from "./icons/PassionCardIcon";
import { Autoplay, Pagination } from "swiper/modules";
import { motion } from "framer-motion";

const ValuesCarousel = () => {
  const [hoverStates, setHoverStates] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMouseEnter = (id) => {
    if (!isMobile) {
      setHoverStates((prev) => ({ ...prev, [id]: true }));
    }
  };

  const handleMouseLeave = (id) => {
    if (!isMobile) {
      setHoverStates((prev) => ({ ...prev, [id]: false }));
    }
  };

  const renderCard = (id, title, description, FrontIcon, BackIcon) => (
    <div
      className="h-[375px] w-[311px] flex flex-col items-center justify-center group perspective-1000 relative"
      onMouseEnter={() => handleMouseEnter(id)}
      onMouseLeave={() => handleMouseLeave(id)}>
      <motion.div
        className="absolute h-full w-full border border-legno"
        animate={{ rotateY: hoverStates[id] || isMobile ? 180 : 0 }}
        transition={{ duration: 0.6 }}
        style={{ transformStyle: "preserve-3d" }}>
        <motion.div
          className="absolute h-full w-full flex items-center justify-center bg-white"
          style={{ backfaceVisibility: "hidden" }}>
          <FrontIcon />
        </motion.div>
        <motion.div
          className="absolute h-full w-full flex flex-col items-center justify-center text-center gap-2 bg-porpora"
          style={{
            backfaceVisibility: "hidden",
            transform: "rotateY(180deg)",
          }}>
          <BackIcon />
          <h3 className="text-white text-xl">{title}</h3>
          <p className="text-white text-sm mx-12">{description}</p>
        </motion.div>
      </motion.div>
    </div>
  );

  return (
    <div className="my-8">
      <Swiper
        centeredSlides={true}
        autoplay={true}
        navigation={false}
        grabCursor={true}
        modules={[Autoplay, Pagination]}
        breakpoints={{
          // when window width is >= 768px
          768: {
            centeredSlides: false,
            slidesPerView: 3,
            allowSlidePrev: false,
            allowSlideNext: false,
            noSwiping: true,
            noSwipingClass: "swiper-no-swiping", // class to apply when noSwiping is enabled
            spaceBetween: 10,
          },
          // when window width is < 768px
          0: {
            centeredSlides: true,
            slidesPerView: 1,
            allowSlidePrev: true,
            allowSlideNext: true,
            noSwiping: false,
            spaceBetween: -50,
          },
        }}>
        <SwiperSlide>
          {renderCard(
            1,
            "QUALITY",
            "Our dedication lies in consistently delivering artisanal pizzas that exceed expectations. We're devoted to the art and science of pizza, constantly refining our techniques to share the joy of authentic, handcrafted pizza with our community.",
            QualityCardIcon,
            QualityIcon
          )}
        </SwiperSlide>
        <SwiperSlide>
          {renderCard(
            2,
            "DEDICATION",
            "We are dedicated to our craft and committed to delivering the highest quality in everything we do. Our passion for pizza drives us to innovate and perfect our recipes continually.",
            DedicationCardIcon,
            DedicationIcon
          )}
        </SwiperSlide>
        <SwiperSlide>
          {renderCard(
            3,
            "PASSION",
            "Our passion for pizza is unmatched. We believe in the power of pizza to bring joy and satisfaction, and we pour our heart and soul into every pie we make.",
            PassionCardIcon,
            PassionIcon
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ValuesCarousel;
