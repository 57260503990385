const InstagramIcon = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9244 27.4056H5.07555C2.60908 27.4056 0.594055 25.3906 0.594055 22.9241V5.07524C0.594055 2.60877 2.60908 0.59375 5.07555 0.59375H22.9244C25.3909 0.59375 27.4059 2.60877 27.4059 5.07524V22.9241C27.4059 25.4016 25.4019 27.4056 22.9244 27.4056Z"
        fill="#302713"
      />
      <path
        d="M14.0056 20.8868C12.1668 20.8868 10.438 20.1711 9.13874 18.8718C7.83943 17.5725 7.12372 15.8438 7.12372 14.0049C7.12372 12.1661 7.83943 10.4374 9.13874 9.13807C10.438 7.83876 12.1668 7.12305 14.0056 7.12305C15.8445 7.12305 17.5732 7.83876 18.8725 9.13807C20.1718 10.4374 20.8875 12.1661 20.8875 14.0049C20.8875 15.8438 20.1718 17.5725 18.8725 18.8718C17.5622 20.1711 15.8445 20.8868 14.0056 20.8868ZM14.0056 8.58752C11.0216 8.58752 8.58819 11.0099 8.58819 14.0049C8.58819 16.9889 11.0106 19.4224 14.0056 19.4224C16.9896 19.4224 19.423 16.9999 19.423 14.0049C19.412 11.021 16.9896 8.58752 14.0056 8.58752Z"
        fill="white"
      />
      <path
        d="M22.2289 6.89662C22.9587 6.89662 23.5502 6.30503 23.5502 5.57526C23.5502 4.8455 22.9587 4.25391 22.2289 4.25391C21.4991 4.25391 20.9075 4.8455 20.9075 5.57526C20.9075 6.30503 21.4991 6.89662 22.2289 6.89662Z"
        fill="white"
      />
    </svg>
  );
};
export default InstagramIcon;
