import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

export default function LegalPage() {
  return (
    <div className="overflow-x-hidden">
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="text-5xl font-awesome font-bold mx-4 md:mx-0"
              data-aos="fade-in">
              LEGAL
            </h1>
            <h2
              className="font-awesome-italic italic text-xl mx-4 md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Cookie Policy & Terms of Service
            </h2>
          </div>
        </div>
        <div
          className="mt-16 md:mt-28 md:grid md:grid-cols-12"
          data-aos="fade-up">
          <div className="mx-4 flex flex-col gap-4 md:mx-0 md:col-span-8">
            <h3 className="font-awesome font-bold text-legno text-4xl">
              Cookie Policy
            </h3>
            <p className="font-neuething-regular text-legno text-lg flex md:pb-16 flex-col gap-4 md:gap-8 mt-4">
              <span className="font-neuething-bold">What are cookies?</span>
              Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners to make their websites work, or to work more
              efficiently, as well as to provide reporting information. Cookies
              set by the website owner (in this case, Marc's Artisanal Pizzeria)
              are called "first-party cookies". Cookies set by parties other
              than the website owner are called "third-party cookies".
              Third-party cookies enable third-party features or functionality
              to be provided on or through the website (e.g., advertising,
              interactive content, and analytics).
              <span className="font-neuething-bold">
                Why do we use cookies?
              </span>
              We use first-party and third-party cookies for several reasons.
              Some cookies are required for technical reasons in order for our
              website to operate, and we refer to these as "essential" or
              "strictly necessary" cookies. Other cookies also enable us to
              track and target the interests of our users to enhance the
              experience on our website. Third parties serve cookies through our
              website for advertising, analytics, and other purposes.
              <span className="font-neuething-bold">
                How can I control cookies?
              </span>
              You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services. The Cookie Consent Manager
              can be found in the notification banner and on our website. If you
              choose to reject cookies, you may still use our website though
              your access to some functionality and areas of our website may be
              restricted.
              <span className="font-neuething-bold">
                Changes to the Cookie Policy
              </span>
              We may update this Cookie Policy to reflect changes to our cookie
              practices. Please revisit this policy regularly to stay informed
              about our use of cookies and related technologies. For more
              information about the cookies we use, please contact us at
              info@marcsartisanalpizzeria.com.
            </p>
          </div>
          <div
            className="hidden md:flex md:col-span-3 md:col-start-10 md:justify-center md:text-6xl md:font-awesome md:mx-auto md:border-t-2 md:items-center md:w-full  md:border-legno"
            style={{
              writingMode: "vertical-rl",
              textOrientation: "mixed",
              transform: "rotate(180deg)",
            }}>
            COOKIE POLICY
          </div>

          <div className="mx-4 mt-16 flex flex-col gap-4 md:mx-0 md:col-span-8">
            <h3 className="font-awesome font-bold text-legno text-4xl">
              Terms of Service
            </h3>
            <p className="font-neuething-regular text-legno text-lg flex flex-col gap-4 md:gap-8 mt-4">
              By accessing this website, we assume you accept these terms and
              conditions in full. Do not continue to use Marc's Artisanal
              Pizzeria's website if you do not accept all of the terms and
              conditions stated on this page.
              <span className="font-neuething-bold">
                Intellectual Property Rights
              </span>
              Other than the content you own, under these Terms, Marc's
              Artisanal Pizzeria and/or its licensors own all the intellectual
              property rights and materials contained in this Website. You are
              granted a limited license only for purposes of viewing the
              material contained on this Website.
              <span className="font-neuething-bold">Restrictions</span> You are
              specifically restricted from all of the following: Publishing any
              website material in any media; Selling, sublicensing, and/or
              otherwise commercializing any website material; Publicly
              performing and/or showing any website material; Using this Website
              in any way that is, or may be, damaging to this Website; Using
              this Website in any way that impacts user access to this Website.
              <span className="font-neuething-bold"> Your Content </span> In
              these Website Standard Terms and Conditions, "Your Content" shall
              mean any audio, video text, images, or other material you choose
              to display on this Website. Your Content must be your own and must
              not be infringing on any third party’s rights. Marc's Artisanal
              Pizzeria reserves the right to remove any of Your Content from
              this Website at any time, without notice.
              <span className="font-neuething-bold">No warranties</span> This
              Website is provided "as is," with all faults, and Marc's Artisanal
              Pizzeria expresses no representations or warranties, of any kind
              related to this Website or the materials contained on this
              Website.
              <span className="font-neuething-bold">
                Limitation of liability
              </span>
              In no event shall Marc's Artisanal Pizzeria, nor any of its
              officers, directors, and employees, be held liable for anything
              arising out of or in any way connected with your use of this
              Website whether such liability is under contract, tort or
              otherwise.
              <span className="font-neuething-bold">Variation of Terms</span>
              Marc's Artisanal Pizzeria is permitted to revise these terms at
              any time as it sees fit, and by using this Website, you are
              expected to review such Terms on a regular basis to ensure you
              understand all terms and conditions governing the use of this
              Website.
              <span className="font-neuething-bold">
                Governing Law & Jurisdiction
              </span>
              These Terms will be governed by and interpreted in accordance with
              the laws of the State of Florida, and you submit to the
              non-exclusive jurisdiction of the state and federal courts located
              in Florida for the resolution of any disputes.
            </p>
          </div>
          <div
            className="hidden md:flex md:col-span-3 md:col-start-10 md:justify-center md:text-6xl md:font-awesome md:mx-auto  md:items-center md:w-full "
            style={{
              writingMode: "vertical-rl",
              textOrientation: "mixed",
              transform: "rotate(180deg)",
            }}>
            TERMS OF SERVICE
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
