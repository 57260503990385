const PassionCardIcon = () => {
  return (
    <svg
      width="137"
      height="177"
      viewBox="0 0 137 177"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M94.5946 95.0613L109.226 89.75C111.446 88.9405 113.936 89.4925 115.616 91.1729L124.275 99.8328C130.947 106.506 130.947 117.312 124.275 123.985L123.514 124.745C116.843 131.418 106.037 131.418 99.3655 124.745L90.7067 116.086C89.0387 114.417 88.4868 111.927 89.284 109.695L94.5946 95.0613ZM94.5946 95.0613L34.4736 34.9448"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <circle
        cx="31.8896"
        cy="32.125"
        r="3.5"
        stroke="#302713"
        stroke-width="2"
      />
      <path
        d="M116.412 58.6266C114.749 58.6735 113.227 59.7064 112.39 61.1267C111.552 59.7182 110.031 58.6735 108.367 58.6266C105.784 58.5561 103.378 60.8919 103.39 63.4625C103.39 64.8945 103.944 66.303 104.723 67.512C106.834 70.8103 111.753 74.1907 112.39 74.625C113.038 74.1907 117.945 70.822 120.057 67.512C120.835 66.303 121.378 64.8945 121.39 63.4625C121.401 60.8919 118.995 58.5561 116.412 58.6266Z"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M43.1887 95.0613L48.5 109.695C49.3095 111.915 48.7576 114.405 47.0771 116.086L38.4172 124.745C31.7444 131.418 20.9379 131.418 14.2651 124.745L13.5046 123.985C6.8318 117.312 6.8318 106.506 13.5046 99.8328L22.1645 91.1729C23.8327 89.5047 26.3227 88.9527 28.5552 89.75L43.1887 95.0613ZM43.1887 95.0613L103.305 34.9448"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <circle
        cx="105.89"
        cy="32.125"
        r="3.5"
        stroke="#302713"
        stroke-width="2"
      />
      <path
        d="M69.9034 129.451C73.5542 129.451 76.5137 126.492 76.5137 122.841C76.5137 119.19 73.5542 116.231 69.9034 116.231C66.2525 116.231 63.293 119.19 63.293 122.841C63.293 126.492 66.2525 129.451 69.9034 129.451Z"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M62.5518 117.023C64.3699 115.01 66.9933 113.75 69.9024 113.75C72.4349 113.75 74.7336 114.698 76.4868 116.256"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M68.1494 113.906V98.8928C68.1494 97.9448 68.9286 97.1655 69.8767 97.1655C70.8247 97.1655 71.604 97.9448 71.604 98.8928V113.893"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M69.9025 123.893C70.4835 123.893 70.9545 123.422 70.9545 122.841C70.9545 122.26 70.4835 121.789 69.9025 121.789C69.3216 121.789 68.8506 122.26 68.8506 122.841C68.8506 123.422 69.3216 123.893 69.9025 123.893Z"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M79.0068 15.4526C78.0693 17.1715 76.585 18.556 74.7803 19.3727C75.0146 18.4704 75.1396 17.5215 75.1396 16.5493C75.1396 12.1391 72.585 8.33562 68.874 6.5C68.4365 8.30451 67.5615 9.93791 66.3584 11.268C64.7412 13.0802 61.5068 16.0048 61.5068 16.0048C59.2725 18.1749 57.8896 21.2005 57.8896 24.5529C57.8896 31.1487 63.2646 36.5 69.8896 36.5C76.5146 36.5 81.8896 31.1487 81.8896 24.5529C81.8818 21.4417 80.8271 18.1049 79.0068 15.4526Z"
        stroke="#302713"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.4119 58.6266C26.7487 58.6735 25.2271 59.7064 24.3896 61.1267C23.5522 59.7182 22.0306 58.6735 20.3674 58.6266C17.7842 58.5561 15.3779 60.8919 15.3897 63.4625C15.3897 64.8945 15.9441 66.303 16.7226 67.512C18.834 70.8103 23.7527 74.1907 24.3896 74.625C25.0384 74.1907 29.9453 70.822 32.0567 67.512C32.8352 66.303 33.3778 64.8945 33.3896 63.4625C33.4014 60.8919 30.9951 58.5561 28.4119 58.6266Z"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M0.459063 176.5V157.3H7.41906C11.1151 157.3 13.8991 159.82 13.8991 163.156C13.8991 166.612 11.2591 169.204 7.75506 169.204H2.81106V176.5H0.459063ZM2.81106 167.116H7.65906C10.2991 167.116 11.4991 165.34 11.4991 163.156C11.4991 161.068 10.2031 159.388 7.41906 159.388H2.81106V167.116ZM18.9353 176.5L26.1353 157.3H28.8233L36.0233 176.5H33.4313L31.1993 170.236H23.7593L21.5273 176.5H18.9353ZM24.4793 168.172H30.4793L27.4793 159.7L24.4793 168.172ZM50.3374 176.86C46.4254 176.86 43.4734 174.388 43.4734 170.956H45.8734C45.8734 173.14 47.6734 174.748 50.3374 174.748C53.3614 174.748 54.8014 173.356 54.8014 171.46C54.8014 166.78 43.8334 168.676 43.8334 162.34C43.8334 159.196 46.6174 156.94 50.3374 156.94C53.9854 156.94 56.7214 159.292 56.7214 162.58H54.3214C54.3214 160.54 53.0974 159.052 50.3374 159.052C47.5054 159.052 46.2334 160.444 46.2334 162.34C46.2334 166.54 57.2014 164.716 57.2014 171.46C57.2014 174.604 54.2494 176.86 50.3374 176.86ZM72.4624 176.86C68.5504 176.86 65.5984 174.388 65.5984 170.956H67.9984C67.9984 173.14 69.7984 174.748 72.4624 174.748C75.4864 174.748 76.9264 173.356 76.9264 171.46C76.9264 166.78 65.9584 168.676 65.9584 162.34C65.9584 159.196 68.7424 156.94 72.4624 156.94C76.1104 156.94 78.8464 159.292 78.8464 162.58H76.4464C76.4464 160.54 75.2224 159.052 72.4624 159.052C69.6304 159.052 68.3584 160.444 68.3584 162.34C68.3584 166.54 79.3264 164.716 79.3264 171.46C79.3264 174.604 76.3744 176.86 72.4624 176.86ZM88.4434 176.5V157.3H90.7954V176.5H88.4434ZM107.135 176.86C102.863 176.86 99.6709 172.588 99.6709 166.9C99.6709 161.212 102.863 156.94 107.135 156.94C111.407 156.94 114.599 161.212 114.599 166.9C114.599 172.588 111.407 176.86 107.135 176.86ZM102.071 166.9C102.071 171.34 103.991 174.748 107.135 174.748C110.279 174.748 112.199 171.34 112.199 166.9C112.199 162.46 110.279 159.052 107.135 159.052C103.991 159.052 102.071 162.46 102.071 166.9ZM123.483 176.5V157.3H126.939L134.187 173.932V157.3H136.539V176.5H132.843L125.835 159.868V176.5H123.483Z"
        fill="#302713"
      />
    </svg>
  );
};
export default PassionCardIcon;
