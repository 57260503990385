const Newsletter = () => {
  return (
    <>
      <div className="text-center md:text-start my-16 mx-4 border-t-2 border-legno md:border-0 relative">
        <h2 className="text-5xl text-legno mt-6 mb-2 font-awesome font-bold leading-[66px]">
          NEWSLETTER
        </h2>
        <h3 className="text-legno text-2xl font-awesome-italic italic ">
          Stay Topped!
        </h3>
        {/* Original mobile layout */}
        <div className="relative flex flex-col gap-2 my-4 md:hidden">
          <input
            type="email"
            id="email"
            className="border-2 font-neuething-medium border-legno rounded-full w-full h-16 text-xl text-opacity-50 text-center"
            placeholder="Your email"
            required
          />
          <button className="bg-carbone font-neuething-medium text-white rounded-full h-16 text-xl">
            SUBSCRIBE
          </button>
        </div>
        {/* New desktop layout */}
        <div className="hidden md:flex items-center gap-2 my-4">
          <input
            type="email"
            id="email"
            className="border-2 font-neuething-medium border-legno rounded-full w-full h-12 pl-8 text-md text-opacity-50"
            placeholder="Your email"
            required
          />
          <button className="bg-carbone font-neuething-medium text-white rounded-full h-12 text-md absolute right-0 px-4">
            SUBSCRIBE
          </button>
        </div>
      </div>
    </>
  );
};

export default Newsletter;
