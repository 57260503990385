import { Link } from "react-router-dom";

const FollowUsButton = () => {
  return (
    <div className="w-64 text-legno hover:text-white hover:bg-legno transition-all duration-300 ease-in-out border-2 border-legno px-6 rounded-full text-xl">
      <Link to="/">
        <h2 className="py-2 text-center">FOLLOW US</h2>
      </Link>
    </div>
  );
};
export default FollowUsButton;
