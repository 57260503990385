import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import AccordionOpenIcon from "../components/icons/AccordionOpenIcon";
import TuscanSunDelightIcon from "../images/TuscanSunDelight.jpg";
import config from "../config"; // Import your config or API URL here

const fetchFaqs = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  try {
    const response = await fetch(`${config.api}/api/faqs`, reqOptions);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data.data; // Adjust based on your API response
  } catch (error) {
    console.error("Failed to fetch FAQs:", error);
    throw error;
  }
};

// Helper function to convert Answer object to plain text
const extractAnswerText = (answer) => {
  return answer
    .map((part) => part.children.map((child) => child.text).join(""))
    .join("\n"); // Assuming answers are separated by new lines
};

export default function FaqsPage() {
  const [faqs, setFaqs] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getFaqs = async () => {
      try {
        const faqsData = await fetchFaqs();
        if (Array.isArray(faqsData)) {
          const formattedFaqs = faqsData.map((faq) => ({
            question: faq.attributes.Question,
            answer: extractAnswerText(faq.attributes.Answer),
            isOpen: false,
            isHovered: false,
          }));
          setFaqs(formattedFaqs);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getFaqs();
  }, []);

  const toggleContent = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  const handleMouseEnter = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isHovered: true } : { ...faq, isHovered: false }
      )
    );
  };

  const handleMouseLeave = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) => ({ ...faq, isHovered: false }))
    );
  };

  const contentVariants = {
    open: { height: "auto", opacity: 1, transition: { duration: 0.3 } },
    closed: { height: 0, opacity: 0, transition: { duration: 0.3 } },
  };

  const menuItems = [
    {
      name: "Tuscan Sun Delight",
      price: "$17.99",
      description:
        "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
      image: TuscanSunDelightIcon,
    },
    // Add more menu items as needed
  ];

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-x-hidden">
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="text-5xl md:text-6xl font-awesome font-bold mx-4 md:mx-0"
              data-aos="fade-in">
              FAQs
            </h1>
            <h2
              className="font-awesome-italic italic text-xl md:text-2xl mx-4 md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Find answers to the most commonly asked questions below.
            </h2>
          </div>
        </div>
      </div>

      <div className="md:container md:mx-auto md:grid md:grid-cols-12">
        <div className="md:col-span-7 md:my-10">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="mx-4 relative mt-0 md:mx-0 border-b-2 border-legno overflow-visible py-12"
              data-aos="fade-up"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}>
              <div className="mx-4 flex flex-col md:mx-0">
                <AnimatePresence>
                  <h3 className="font-awesome font-bold text-legno text-4xl">
                    {faq.question}
                  </h3>
                  <motion.p
                    className={`font-awesome-italic italic my-4 text-porpora text-xl ${
                      faq.isOpen ? "" : "hidden"
                    }`}
                    animate={faq.isOpen ? "open" : "closed"}
                    variants={contentVariants}>
                    {faq.answer}
                  </motion.p>
                </AnimatePresence>
              </div>
              {/* Icon container for hover effect */}
              {faq.isHovered && (
                <motion.div
                  className="absolute right-4 -bottom-8 opacity-100 transition-opacity"
                  onClick={() => toggleContent(index)}
                  whileHover={{ scale: 1.1 }}>
                  <AccordionOpenIcon />
                </motion.div>
              )}
            </div>
          ))}
        </div>
        <div className="md:col-span-4 md:col-start-10 my-20">
          {menuItems.map((menuItem, index) => (
            <div
              key={index}
              className="border-black border-2 mb-6 p-6 mx-8 md:mx-0 md:w-auto md:h-auto bg-white flex flex-col w-full"
              data-aos="fade-in"
              data-aos-delay="200">
              <div className="flex justify-between mb-2">
                <h3 className="text-2xl font-awesome font-bold">
                  {menuItem.name}
                </h3>
                <h4 className="text-2xl font-awesome-italic italic">
                  {menuItem.price}
                </h4>
              </div>
              <h4 className="text-sm  font-awesome-italic italic leading-5 mb-6">
                {menuItem.description}
              </h4>
              <img
                src={menuItem.image}
                alt={menuItem.name}
                className="md:h-[180px] md:object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}
