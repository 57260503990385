import NavBar from "../components/NavBar";
import HeroBanner from "../components/HeroBanner";
import OrderNowButton from "../components/OrderNowButton";
import CheckOurMenuButton from "../components/CheckOurMenuButton";
import Cards from "../components/Cards";

import MenuItemImage1 from "../images/MenuItemImage1.jpg";
import Ingredients from "../components/Ingredients";
import Blog from "../components/Blog";
import Carousel from "../components/Carousel";
import InstagramGrid from "../components/InstagramGrid";
import Footer from "../components/Footer";
import FunnyBadgeIcon from "../components/icons/FunnyBadgeIcon";
// import TextScrollComponent from "../components/TextScrollComponent";

import HeroImage from "../images/HeroImage.jpg";
import ParralaxImage from "../images/ParralaxImage.jpg";

import React, { useState, useEffect, useRef } from "react";
import TextScrollIcon from "../components/icons/TextScrollIcon";
import { Autoplay, Parallax, ParallaxBanner } from "react-scroll-parallax";
import TextScrollComponent from "../components/TextScrollComponent";

export default function HomePage() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      // Throttle or debounce scroll event for smoother performance
      // Here's an example of throttling
      throttle(setScrollPosition(window.scrollY), 100); // Adjust throttle time as needed
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = "Marc's Artisanal Pizzeria";
  }, []);

  // Throttle function to limit the rate at which the scroll event is processed
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };
  const stickyElement = useRef(null);

  return (
    <div className="overflow-hidden bg-white ">
      <div className="z-10 ">
        <NavBar ref={stickyElement} />
        <div className="z-0 cursor-none">
          <HeroBanner stickyElement={stickyElement} />
        </div>
      </div>

      <div className="z-10 relative bg-white">
        <div className="container mx-auto ">
          <div className=" mx-auto px-4 md:px-0 md:grid md:grid-cols-12 md:py-32 md:gap-8">
            <div className=" bg-white mx-auto my-16 md:my-0 md:mx-0 md:col-span-8">
              <h2
                className="text-5xl leading-[4rem] font-awesome md:text-6xl font-bold md:leading-[80px]"
                data-aos="fade-right"
                data-aos-delay="100">
                Hi Miami!
              </h2>
              <h3
                className="text-porpora my-6 text-xl font-awesome-italic italic md:text-2xl"
                data-aos="fade-right"
                data-aos-delay="200">
                Hi Miami!
              </h3>
              <p
                className="mb-6 text-lg text-legno font-neuething-regular"
                data-aos="fade-right"
                data-aos-delay="300">
                We respect and honor tradition. That’s why we use only the
                freshest and sustainably sourced ingredients. Sharing a meal is
                a powerful moment of connection and joy. We make the perfect
                pies for you to enjoy a real meal, so you can forget your busy
                schedule, put away your phone, and savor the genuine taste of
                our handcrafted pizza. 
              </p>
              <div
                className="pb-16 md:pb-0"
                data-aos="zoom-in"
                data-aos-delay="400">
                <CheckOurMenuButton />
              </div>
            </div>
            <div className="hidden md:flex md:col-span-2 md:col-start-11">
              <div
                className="flex flex-col min-h-[520px]"
                style={{
                  writingMode: "vertical-rl",
                  textOrientation: "mixed",
                  transform: "rotate(180deg)",
                }}>
                <div
                  className="border border-legno text-4xl text-center font-neuething-regular-expanded px-5 "
                  data-aos="fade-right"
                  data-aos-delay="400">
                  NO SLICES
                </div>
                <div
                  className="border border-legno text-4xl text-center font-neuething-regular-expanded px-5"
                  data-aos="fade-right"
                  data-aos-delay="800">
                  WALK-INS ONLY
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex max-w-[1600px] justify-center mx-auto">
          {/* Image div placed outside of the container */}
          <div
            className=" absolute md:relative    w-full  z-0 md:z-10"
            data-aos="fade-in">
            <img
              src={MenuItemImage1}
              alt="Menu item one"
              className="h-[500px] w-full object-cover -top-10  md:top-0 absolute md:flex"
            />
          </div>
          <div className="container pt-32 md:pt-0 mx-auto px-0 ">
            <div className="md:grid md:grid-cols-1">
              <div className="" data-aos="fade-right">
                <Cards />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 md:mt-0">
          <Ingredients />
        </div>

        <div className="my-16 md:my-40 overflow-hidden">
          {/* Text scroll sections with smooth scroll */}
          <div
            className="flex scale-75 md:scale-100"
            style={{
              scrollBehavior: "smooth",
              transition: "transform 0.5s ease-out", // Adjust timing and easing as needed
            }}
            ref={containerRef}>
            <div
              className="flex"
              style={{
                transform: `translateX(calc(-20% + ${
                  scrollPosition / 5
                }px - 1000px))`, // Adjust the divisor here to slow down the movement
              }}>
              {Array.apply(null, { length: 2 }).map((e, i) => (
                <TextScrollIcon key={i} />
              ))}
            </div>

            <div
              className="flex mt-28 pt-8 border-t-2 border-legno"
              style={{
                transform: `translateX(calc(-50% - ${scrollPosition / 5}px))`, // Adjust the divisor here to slow down the movement
              }}>
              {Array.apply(null, { length: 4 }).map((e, i) => (
                <TextScrollIcon key={i} />
              ))}
            </div>
          </div>
        </div>

        <div className="">
          <Blog />
        </div>

        <div className="">
          <div className="px-4 md:px-0">
            <div
              className="mx-auto container border-t-2 border-legno md:w-full"
              data-aos="fade-up">
              <h2 className="text-5xl leading-[4rem]  pt-4 md:pt-8  my-6 text-center text-legno md:mt-10 font-bold font-awesome md:text-6xl">
                We Can’t Wait to See You!
              </h2>
              <p
                className="text-legno text-xl text-center md:text-porpora md:text-2xl md:text-center md:mx-52 font-awesome-italic italic md:mb-12"
                data-aos="fade-up">
                We cherish our traditions, embracing the values of family, love,
                and dedication. Come experience the warmth and heritage that
                inspire every dish at Marc's .
              </p>
            </div>
          </div>
          <div
            className="mb-16  mt-6 mx-auto md:w-full max-h-[900px] overflow-hidden"
            data-aos="fade-up">
            <ParallaxBanner
              layers={[
                {
                  image: ParralaxImage,
                  speed: -20,
                  translateY: [-5, 5],
                },
              ]}
              style={{ aspectRatio: "2 / 1" }}
            />

            {/* <Carousel /> */}
          </div>
        </div>

        <InstagramGrid />

        <Footer />
      </div>
    </div>
  );
}
