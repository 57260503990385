import React from "react";

const PreviousButtonIcon = ({ color, inactive }) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity={inactive ? "0.5" : "1"}>
        <rect
          x="-1"
          y="1"
          width="50"
          height="50"
          rx="25"
          transform="matrix(-1 0 0 1 50 0.00012207)"
          stroke={color}
          strokeWidth="2"
        />
        <path
          d="M20 26.0001L19.2929 26.7072L18.5858 26.0001L19.2929 25.293L20 26.0001ZM30.7071 16.7072L20.7071 26.7072L19.2929 25.293L29.2929 15.293L30.7071 16.7072ZM20.7071 25.293L30.7071 35.293L29.2929 36.7072L19.2929 26.7072L20.7071 25.293Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default PreviousButtonIcon;
