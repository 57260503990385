import React, { useState, useEffect, useRef } from "react";
import ValuesCarousel from "../components/ValuesCarousel";
import HeroBannerAbout from "../components/HeroBannerAbout";
import NavBar from "../components/NavBar";

import KitchenImage from "../images/KitchenImage.jpg";
import OurStoryBannerMobile from "../images/OurStoryBannerMobile.jpg";
import OurStoryBannerDesktop from "../images/OurStoryBannerDesktop.jpg";
import Ingredients from "../components/Ingredients";
import FounderImage from "../images/FounderImage.jpg";
import Testimonials from "../components/Testimonials";
import Footer from "../components/Footer";
import MLogoIcon from "../components/icons/MLogoIcon";
import TextScrollIcon from "../components/icons/TextScrollIcon";
import OrderNowButton from "../components/OrderNowButton";
import TextScrollComponent from "../components/TextScrollComponent";

export default function AboutUsPage() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="overflow-hidden">
        <NavBar />
        <HeroBannerAbout />
        <div className="md:grid md:grid-cols-12 md:mt-32 md:mx-auto md:container">
          <div className="mx-8 mt-12 mb-8 bg-white md:my-0 md:mx-0 md:col-span-6 ">
            <h2
              className="text-5xl leading-[4rem] md:text-6xl font-awesome font-bold md:leading-[80px] text-legno "
              data-aos="fade-right">
              The Spark of Creation
            </h2>
            <h3
              className="my-6 text-xl text-porpora font-awesome-italic italic "
              data-aos="fade-right"
              data-aos-delay="100">
              Discover the story behind Marc's Artisanal Pizzeria.
            </h3>
            <p
              className="mb-6 text-lg  text-legno font-neuething-regular"
              data-aos="fade-right"
              data-aos-delay="300">
              Inspired by the warmth of family tradition and driven by a passion
              for exceptional hospitality, Eric and Yeanny set to recreate that
              authentic feeling through the art of pizza making. In the heart of
              Miami, their dream flourished into reality, to open a place that
              feels like home. 
            </p>
          </div>
          <div
            className="p-6 mx-4 md:mx-0 border-2 border-legno md:border-0 md:col-span-6 relative "
            data-aos="fade-up"
            data-aos-delay="300">
            <div className="md:absolute md:-top-48">
              <img
                src={KitchenImage}
                alt="Kitchen Pizza"
                className="md:bg-white md:p-12"
              />
            </div>
          </div>
        </div>

        <div className="mx-8 md:mx-auto md:container mt-16 md:mt-0 md:py-24 xl:pt-52 flex flex-col md:gap-6">
          <h2
            className="mb-6 text-4xl text-center md:text-[64px] font-awesome font-bold"
            data-aos="fade-in">
            Marc's Philosophy
          </h2>
          <h3
            className="text-xl md:text-2xl text-center text-porpora font-awesome-italic italic leading-[30px]"
            data-aos="fade-in"
            data-aos-delay="100">
            Quality, dedication, and passion are the pillars define everything
            at Marc’s. Our journey expresses these values, ensuring that every
            pie is an experience of community and craft.
          </h3>
        </div>
        <div className="md:container mb-16 md:mb-0">
          <ValuesCarousel />
        </div>

        <div className="relative">
          <div
            className="absolute inset-0 bg-center bg-cover"
            style={{
              backgroundImage: `url(${
                window.innerWidth >= 768
                  ? OurStoryBannerDesktop
                  : OurStoryBannerMobile
              })`,
            }}
          />
          <div className="relative z-10 md:mt-32">
            <div className="container py-16 px-8 mx-auto md:py-32 md:px-20 md:grid md:grid-cols-2">
              <div className="flex flex-col justify-center gap-6">
                <h2
                  className="text-4xl text-white font-awesome font-bold md:text-6xl"
                  data-aos="fade-right">
                  The Art of Pizza Making
                </h2>
                <h3
                  className="text-xl text-white font-awesome-italic italic leading-[30px]"
                  data-aos="fade-right"
                  data-aos-delay="200">
                  Rise to perfection.
                </h3>
                <p
                  className="text-white text-lg font-neuething-regular"
                  data-aos="fade-right"
                  data-aos-delay="400">
                  The soul of our pizza lies in its creation. Traditional
                  techniques blend into craftsmanship in our kitchen. Each pie
                  is a masterpiece of taste and texture. Our dough, with its
                  perfect rise, is hand-stretched to breathe before baking,
                  giving a unique and irresistible crust that is Marc's
                  signature. This is where tradition meets innovation, and magic
                  happens.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* INGRIDIENTS */}
        <div className="mt-16 md:mt-0">
          <Ingredients />
        </div>

        <div className="my-16 md:my-40 overflow-hidden">
          {/* Text scroll sections with smooth scroll */}
          <div
            className="flex scale-75 md:scale-100"
            style={{
              scrollBehavior: "smooth",
              transition: "transform 0.5s ease-out", // Adjust timing and easing as needed
            }}
            ref={containerRef}>
            <div
              className="flex"
              style={{
                transform: `translateX(calc(-20% + ${
                  scrollPosition / 5
                }px - 1000px))`, // Adjust the divisor here to slow down the movement
              }}>
              {Array.apply(null, { length: 2 }).map((e, i) => (
                <TextScrollIcon key={i} />
              ))}
            </div>

            <div
              className="flex mt-28 pt-8 border-t-2 border-legno"
              style={{
                transform: `translateX(calc(-50% - ${scrollPosition / 5}px))`, // Adjust the divisor here to slow down the movement
              }}>
              {Array.apply(null, { length: 4 }).map((e, i) => (
                <TextScrollIcon key={i} />
              ))}
            </div>
          </div>
        </div>

        {/* TEAM */}
        <div className="my-16 md:my-24 mx-8 text-center flex flex-col gap-6 md:container md:mx-auto">
          <h2
            className="text-5xl leading[4rem] md:leading-[80px] md:text-6xl font-awesome font-bold"
            data-aos="fade-in">
            Our Story
          </h2>
          <p
            className="text-porpora text-xl md:text-base font-neuething-regular leading-[30px]"
            data-aos="fade-in"
            data-aos-delay="200">
            Marc's Artisanal Pizzeria is the culmination of our journey as a
            husband and wife team—a blend of two cultures and one shared dream.
            From Manhattan and Caracas to Miami's culinary scene, our story
            merges New Jersey's flavors, the Basque region's traditions, and
            Venezuela's spirit.
            <br />
            <br />
            With one of us trained at Johnson & Wales University and the other
            bringing Venezuelan hospitality, we met in a French bakery. Our
            experience led us to several pizzerias, learning and dreaming of our
            own place.
            <br />
            <br /> Named in honor of a beloved family patriarch known for his
            integrity and love for family, Marc's Artisanal Pizzeria is a
            tribute to our values. We invite you to enjoy quality ingredients
            and the warmth of a family gathering. <br />
            <br />
            Join us at Marc's, where every dish celebrates our culinary
            traditions and community spirit.
          </p>
          <span className="text-porpora text-xl md:text-2xl font-awesome-italic italic leading-[30px]">
            Welcome to our family.
          </span>

          <div className="flex flex-col md:flex-row mt-8 justify-center gap-4 md:gap-8">
            <div
              className="border-2 border-legno p-8 md:pb-12"
              data-aos="fade-up"
              data-aos-delay="400"
              style={{ maxWidth: "500px", maxHeight: "500px" }}>
              {/* <img
                src={FounderImage}
                alt="Founder"
                className="md:w-full md:h-full object-cover"
              /> */}
              <div className="flex justify-between w-full mt-2 text-xl text-legno">
                <span className="font-awesome">Eric</span>
                <span className="font-awesome-italic italic">Chef/Owner</span>
              </div>
            </div>
            <div
              className="border-2 border-legno p-8 md:pb-12"
              data-aos="fade-up"
              data-aos-delay="600"
              style={{ maxWidth: "500px", maxHeight: "500px" }}>
              {/* <img
                src={FounderImage}
                alt="Founder"
                className="md:w-full md:h-full object-cover"
              /> */}
              <div className="flex justify-between w-full mt-2 text-xl text-legno">
                <span className="font-awesome">Yeanny</span>
                <span className="font-awesome-italic italic">Chef/Owner</span>
              </div>
            </div>
          </div>

          {/* TESTIMONIALS */}
        </div>
        <div className="bg-[#F7E8E8] py-32 hidden">
          <div className="md:container md:grid md:grid-cols-12 md:gap-8">
            <div className="px-8 md:container md:mx-auto md:col-span-6 md:px-0 md:flex md:flex-col md:justify-center">
              <h2
                className="text-5xl leading-[4rem] text-legno mb-6 md:text-6xl font-awesome font-bold md:leading-[80px]"
                data-aos="fade-right">
                Voices from Our Table: Guest Testimonials
              </h2>
              <p
                className="text-legno font-neuething-regular leading-7"
                data-aos="fade-right"
                data-aos-delay="200">
                At Marc's Artisanal Pizzeria, we believe our guests are the
                heart of our story. Their experiences, shared in their own
                words, reflect the warmth, quality, and authenticity we strive
                to deliver with every slice. Discover why our community keeps
                coming back for more through their testimonials.
              </p>
            </div>
            <div
              className="md:col-span-6 md:col-start-7 "
              data-aos="fade-right"
              data-aos-delay="600">
              <Testimonials />
            </div>
          </div>
        </div>

        {/* FINAL CTA */}
        <div className="relative mb-16 flex flex-col items-center text-center py-44 gap-9 overflow-hidden">
          <div className="absolute top-0 scale-75">
            <MLogoIcon />
          </div>
          <h2
            className="text-5xl leading-[4rem] md:text-6xl text-legno font-awesome font-bold md:leading-[80px]"
            data-aos="fade-in">
            Ready for a Taste Adventure? <br /> Order Now!
          </h2>
          <div
            className="bg-white rounded-full z-10"
            data-aos="fade-up"
            data-aos-delay="300">
            <OrderNowButton color="black" />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
