import DownloadPdfButton from "../components/DownloadPdfButton";
import Footer from "../components/Footer";
import MenuCarousel from "../components/MenuCarousel";
import NavBar from "../components/NavBar";
import OrderNowButton from "../components/OrderNowButton";
import LogoIcon from "../components/icons/LogoIcon";
import TuscanSunDelightIcon from "../images/TuscanSunDelight.jpg";
export default function MenuPage() {
  return (
    <div className="overflow-hidden">
      <NavBar showImmediately={true} />

      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="text-5xl md:text-6xl font-awesome font-bold mx-4 md:mx-0"
              data-aos="fade-in">
              OUR MENU
            </h1>
            <h2
              className="font-awesome-italic italic text-xl md:text-2xl mx-4 md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Explore Artisanal Pizzas & Handpicked Delights
            </h2>
          </div>
          <div className="hidden md:flex" data-aos="fade-left">
            <OrderNowButton color="black" />
          </div>
        </div>
      </div>

      <MenuCarousel
        category="Appetizers & Salads"
        menuItems={[
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
        ]}
      />

      <MenuCarousel
        category="Pizza"
        menuItems={[
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
        ]}
      />
      <MenuCarousel
        category="Desserts"
        menuItems={[
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
        ]}
      />
      <MenuCarousel
        category="Drinks"
        menuItems={[
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
          {
            name: "Tuscan Sun Delight",
            price: "$17.99",
            description:
              "Sun-dried tomatoes, mozzarella, spinach, artichoke hearts, Kalamata olives, and a drizzle of garlic-infused olive oil.",
            image: TuscanSunDelightIcon,
          },
        ]}
      />
      <div className="container flex w-full justify-center mb-16">
        <DownloadPdfButton />
      </div>
      <Footer />
    </div>
  );
}
