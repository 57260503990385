// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_cursor__tY4fz {
  position: fixed;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: white; /* Start with white border color */
  border-radius: 50%;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/stickyCursor/style.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,6BAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA,EAAA,kCAAA;EACA,kBAAA;EACA,oBAAA;AACF","sourcesContent":[".cursor {\n  position: fixed;\n  width: 15px;\n  height: 15px;\n  background-color: transparent;\n  border-width: 2px;\n  border-style: solid;\n  border-color: white; /* Start with white border color */\n  border-radius: 50%;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursor": `style_cursor__tY4fz`
};
export default ___CSS_LOADER_EXPORT___;
