const PassionIcon = () => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.2156 36.205L41.0305 34.0942C41.9127 33.7725 42.9022 33.9918 43.57 34.6597L47.0111 38.1013C49.6627 40.7532 49.6627 45.0479 47.0111 47.6998L46.7089 48.0021C44.0574 50.654 39.7632 50.654 37.1116 48.0021L33.6705 44.5605C33.0076 43.8975 32.7882 42.9079 33.1051 42.0207L35.2156 36.205ZM35.2156 36.205L11.3223 12.3135"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M11.5846 11.1929C11.5846 11.9045 11.0078 12.4813 10.2962 12.4813C9.58465 12.4813 9.00781 11.9045 9.00781 11.1929C9.00781 10.4814 9.58465 9.90454 10.2962 9.90454C11.0078 9.90454 11.5846 10.4814 11.5846 11.1929Z"
        stroke="white"
      />
      <path
        d="M43.8863 21.7252C43.2253 21.7439 42.6206 22.1544 42.2877 22.7188C41.9549 22.1591 41.3502 21.7439 40.6892 21.7252C39.6626 21.6972 38.7063 22.6255 38.711 23.6471C38.711 24.2162 38.9313 24.776 39.2407 25.2565C40.0798 26.5673 42.0346 27.9107 42.2877 28.0833C42.5455 27.9107 44.4957 26.5719 45.3348 25.2565C45.6442 24.776 45.8598 24.2162 45.8645 23.6471C45.8692 22.6255 44.9129 21.6972 43.8863 21.7252Z"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M14.786 36.205L16.8968 42.0207C17.2185 42.903 16.9992 43.8926 16.3313 44.5605L12.8897 48.0021C10.2378 50.654 5.94307 50.654 3.29117 48.0021L2.98893 47.6998C0.337023 45.0479 0.337023 40.7532 2.98893 38.1013L6.43056 34.6597C7.09353 33.9967 8.08312 33.7773 8.97034 34.0942L14.786 36.205ZM14.786 36.205L38.6775 12.3135"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M40.9928 11.1929C40.9928 11.9045 40.416 12.4813 39.7044 12.4813C38.9928 12.4813 38.416 11.9045 38.416 11.1929C38.416 10.4814 38.9928 9.90454 39.7044 9.90454C40.416 9.90454 40.9928 10.4814 40.9928 11.1929Z"
        stroke="white"
      />
      <path
        d="M25.4025 49.8724C26.8534 49.8724 28.0296 48.6962 28.0296 47.2453C28.0296 45.7944 26.8534 44.6182 25.4025 44.6182C23.9516 44.6182 22.7754 45.7944 22.7754 47.2453C22.7754 48.6962 23.9516 49.8724 25.4025 49.8724Z"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M22.4805 44.933C23.2031 44.133 24.2456 43.6323 25.4018 43.6323C26.4082 43.6323 27.3218 44.0091 28.0186 44.6285"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M24.7051 43.6944V37.728C24.7051 37.3512 25.0148 37.0415 25.3915 37.0415C25.7683 37.0415 26.078 37.3512 26.078 37.728V43.6893"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M25.4024 47.6635C25.6333 47.6635 25.8205 47.4763 25.8205 47.2455C25.8205 47.0146 25.6333 46.8274 25.4024 46.8274C25.1715 46.8274 24.9844 47.0146 24.9844 47.2455C24.9844 47.4763 25.1715 47.6635 25.4024 47.6635Z"
        stroke="white"
        stroke-miterlimit="10"
      />
      <path
        d="M29.0213 4.56696C28.6487 5.25011 28.0588 5.80034 27.3416 6.12491C27.4347 5.76633 27.4844 5.38921 27.4844 5.00282C27.4844 3.25013 26.4691 1.73855 24.9943 1.00903C24.8205 1.72618 24.4727 2.37533 23.9946 2.90392C23.3519 3.62416 22.0665 4.78644 22.0665 4.78644C21.1785 5.64887 20.6289 6.85133 20.6289 8.18362C20.6289 10.8049 22.765 12.9317 25.398 12.9317C28.0309 12.9317 30.167 10.8049 30.167 8.18362C30.1639 6.94716 29.7447 5.62105 29.0213 4.56696Z"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.9136 21.7252C8.25262 21.7439 7.6479 22.1544 7.31507 22.7188C6.98223 22.1591 6.37751 21.7439 5.71654 21.7252C4.68992 21.6972 3.73361 22.6255 3.7383 23.6471C3.7383 24.2162 3.95862 24.776 4.26802 25.2565C5.10713 26.5673 7.06193 27.9107 7.31507 28.0833C7.57289 27.9107 9.523 26.5719 10.3621 25.2565C10.6715 24.776 10.8871 24.2162 10.8918 23.6471C10.8965 22.6255 9.94022 21.6972 8.9136 21.7252Z"
        stroke="white"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default PassionIcon;
