import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import ArticlesPlaceholderImage from "../images/ArticlesPlaceholderImage.jpg";
import LoadMoreButton from "../components/LoadMoreButton";
import YearPickerButton from "../components/YearPickerButton";
import config from "../config";

const fetchArticles = async () => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/articles?sort=publishedAt:desc`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data; // Extract the array of articles from the response
};

const Article = ({ article }) => {
  const { Title, SubTitle, publishedAt, slug } = article.attributes;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="mx-8 my-8 md:container md:grid md:grid-cols-12 md:gap-8 group md:mx-auto">
      <img
        src={ArticlesPlaceholderImage}
        alt="Article"
        className=" md:col-span-3"
      />
      <div className="flex flex-col my-auto gap-2 md:col-span-6">
        <span className="font-neuething-regular text-legno opacity-50 text-base">
          {formatDate(publishedAt)}
        </span>
        <h2 className="font-awesome font-bold text-4xl leading-[46px]">
          {Title}
        </h2>
        <h3 className="font-awesome-italic italic font-normal text-xl line-clamp-3">
          {SubTitle}
        </h3>
      </div>
      <div className="my-auto md:col-start-11">
        <Link to={`/articles/${slug}`}>
          <button className="relative border-legno md:col-span-2 font-neuething-medium border-2 rounded-full py-2 px-6 mt-8 hover:bg-legno hover:text-white transition-all duration-300 ease-in-out md:h-40 md:w-40 group-hover:border-legno md:group-hover:text-legno md:border-transparent md:text-transparent md:hover:text-white group/button">
            <span className="hidden md:inline font-awesome text-4xl group-hover/button:text-white">
              READ
            </span>
            <span className="md:hidden">READ ARTICLE</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default function ArticlesPage() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getArticles = async () => {
      try {
        const articlesData = await fetchArticles();
        if (Array.isArray(articlesData)) {
          setArticles(articlesData);
        } else {
          setError("Received data is not an array");
        }
      } catch (err) {
        setError(err.message);
      }
    };

    getArticles();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="overflow-x-hidden">
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="text-5xl md:text-6xl font-awesome font-bold mx-4 md:mx-0"
              data-aos="fade-in">
              ARTICLES
            </h1>
            <h2
              className="font-awesome-italic italic text-xl mx-4 md:mx-0 md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Discovering Craftsmanship and Unique Flavors
            </h2>
          </div>
          <div className="hidden md:flex" data-aos="flip-up">
            <YearPickerButton color="black" />
          </div>
        </div>
      </div>

      <div className="md:container md:mx-auto mx-0 px-0 md:px-0">
        {articles.map((article) => (
          <div key={article.id} data-aos="fade-up">
            <Article article={article} />
          </div>
        ))}
      </div>
      <div
        className="my-16 container mx-auto flex justify-center"
        data-aos="flip-up"
        data-aos-delay="200">
        <LoadMoreButton />
      </div>

      <Footer />
    </div>
  );
}
