import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./mystyles.css";

import PizzaMenuIcon from "../images/PizzaImage.jpg";

// import required modules
import { Autoplay, Pagination, EffectCards, Navigation } from "swiper/modules";

const Cards = () => {
  return (
    <div className="mb-8 ">
      <Swiper
        autoplay={{
          delay: 3000,
        }}
        loop={true}
        cardsEffect={{
          rotate: 0,
          perSlideOffset: 10,
          slideShadows: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        effect={""}
        grabCursor={true}
        modules={[Autoplay, Pagination, EffectCards, Navigation]}
        className="mySwiper">
        <SwiperSlide>
          <div className="border-black group md:mr-8 border-2 p-6 bg-white flex flex-col mx-4  w-full ">
            <div className="md:flex md:justify-between md:mb-8">
              <div className="">
                <h2 className="text-4xl font-awesome-italic italic text-legno">
                  Our lunch Special!
                </h2>
                <h3 className="text-xl font-awesome-italic italic text-legno">
                  Artisanal pizza & Beverage of choice
                </h3>
              </div>
              <h4 className="text-4xl mb-6 font-awesome-italic italic text-legno">
                $17.99
              </h4>
            </div>
            <div className="relative w-full flex items-center justify-center ">
              <img
                src={PizzaMenuIcon}
                alt="Pizza "
                className="md:h-[328px] w-full md:object-cover"
              />
              <span className="absolute font-awesome group-hover:text-white transition-all duration-300 ease-in-out font-semibold text-9xl text-transparent ">
                READ
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="border-black group md:mr-8 border-2 p-6 bg-white flex flex-col mx-4  w-full ">
            <div className="md:flex md:justify-between md:mb-8">
              <div className="">
                <h2 className="text-4xl font-awesome-italic italic text-legno">
                  Our lunch Special!
                </h2>
                <h3 className="text-xl font-awesome-italic italic text-legno">
                  Artisanal pizza & Beverage of choice
                </h3>
              </div>
              <h4 className="text-4xl mb-6 font-awesome-italic italic text-legno">
                $17.99
              </h4>
            </div>
            <div className="relative w-full flex items-center justify-center ">
              <img
                src={PizzaMenuIcon}
                alt="Pizza "
                className="md:h-[328px] w-full md:object-cover"
              />
              <span className="absolute font-awesome group-hover:text-white transition-all duration-300 ease-in-out font-semibold text-9xl text-transparent ">
                READ
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="border-black group  md:mr-8 border-2 p-6 bg-white flex flex-col mx-4  w-full ">
            <div className="md:flex md:justify-between md:mb-8">
              <div className="">
                <h2 className="text-4xl font-awesome-italic italic text-legno">
                  Our lunch Special!
                </h2>
                <h3 className="text-xl font-awesome-italic italic text-legno">
                  Artisanal pizza & Beverage of choice
                </h3>
              </div>
              <h4 className="text-4xl mb-6 font-awesome-italic italic text-legno">
                $17.99
              </h4>
            </div>
            <div className="relative w-full flex items-center justify-center ">
              <img
                src={PizzaMenuIcon}
                alt="Pizza "
                className="md:h-[328px] w-full md:object-cover"
              />
              <span className="absolute font-awesome group-hover:text-white transition-all duration-300 ease-in-out font-semibold text-9xl text-transparent ">
                READ
              </span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default Cards;
