import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../config";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

import ArticlesPlaceholderImage from "../images/ArticlesPlaceholderImage.jpg";

const fetchArticle = async (slug) => {
  const reqOptions = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
    },
  };

  const response = await fetch(
    `${config.api}/api/articles?filters[slug][$eq]=${slug}`,
    reqOptions
  );
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const data = await response.json();
  return data.data[0]; // Assuming the response is an array with one article
};

const ArticleDetail = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getArticle = async () => {
      try {
        const articleData = await fetchArticle(slug);
        setArticle(articleData);
      } catch (err) {
        setError(err.message);
      }
    };

    getArticle();
  }, [slug]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!article) {
    return <div>Loading...</div>;
  }

  const { Title, SubTitle, Content, publishedAt } = article.attributes;

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <NavBar showImmediately={true} />

      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2 font-awesome text-2xl font-bold">
            ARTICLES
          </div>
          <div className="hidden md:flex" data-aos="flip-up"></div>
        </div>
      </div>

      <div className="md:container mx-auto px-4">
        <div className=" relative my-auto gap-2 md:grid md:grid-cols-2 md:gap-9 md:pb-32">
          {/* Border element */}
          <div className="absolute -bottom-2 left-0 w-[15%] border-b-2 b border-l-2 h-48 border-black"></div>
          <div className="my-auto flex flex-col gap-4">
            <span className="font-neuething-regular text-legno opacity-50 text-base">
              {formatDate(publishedAt)}
            </span>
            <h1 className="font-awesome font-bold text-4xl md:leading-[50px]">
              {Title}
            </h1>
            <h3 className="font-awesome-italic italic font-normal text-xl text-porpora">
              {SubTitle}
            </h3>
          </div>
          <div className=" w-full h-full ">
            <img
              src={ArticlesPlaceholderImage}
              alt="Article Placeholder"
              className="w-full"
            />
          </div>
        </div>
        <div className="md:grid md:grid-cols-12 md:mb-24">
          <div className="md:col-span-8 md:col-start-3">
            {Content.map((block, index) => (
              <p key={index} className="mb-4">
                {block.children.map((child, i) => (
                  <span key={i} className={child.bold ? "font-bold" : ""}>
                    {child.text}
                  </span>
                ))}
              </p>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArticleDetail;
