import React, { useState } from "react";
import { Link } from "react-router-dom";

const LoadMoreButton = () => {
  const [reachedEnd, setReachedEnd] = useState(false);

  const handleClick = () => {
    setReachedEnd(true);
  };

  return (
    <div className="w-64 text-legno hover:text-white hover:bg-legno transition-all duration-300 ease-in-out border-2 border-legno px-6 rounded-full text-xl">
      {reachedEnd ? (
        <p className="text-center">Looks like you've reached the end</p>
      ) : (
        <Link to="/articles" onClick={handleClick}>
          <h2 className="py-2 text-center">LOAD MORE</h2>
        </Link>
      )}
    </div>
  );
};

export default LoadMoreButton;
