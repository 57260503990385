import React, { useState, useEffect, forwardRef } from "react";
import PizzaMenuIcon from "./icons/PizzaMenuIcon";
import OrderNowButton from "./OrderNowButton";
import LogoIcon from "./icons/LogoIcon";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import PizzaMenuCloseIcon from "./icons/PizzaMenuCloseIcon";
import LogoNoTextIcon from "./icons/LogoNoTextIcon";

const NavBar = forwardRef(({ showImmediately }, ref) => {
  const [scrolled, setScrolled] = useState(showImmediately);
  const [showMenu, setShowMenu] = useState(false); // State to manage menu visibility
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [showLogo, setShowLogo] = useState(true);

  const menuItems = [
    { text: "HOME", to: "/" },
    { text: "MENU", to: "/menu" },
    { text: "OUR STORY", to: "/about" },
    { text: "ARTICLES", to: "/articles" },
    { text: "CONTACTS", to: "/contacts" },
    { text: "FAQ", to: "/faqs" },
    { text: "ORDER ONLINE", to: "https://pos.toasttab.com/" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > prevScrollY) {
        // Scrolling down
        setShowLogo(false);
      } else {
        // Scrolling up
        setShowLogo(true);
      }

      setPrevScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollY]);

  useEffect(() => {
    if (!showImmediately) {
      const handleScroll = () => {
        // const firstSectionHeight = window.innerHeight;
        const isScrolled = window.scrollY > 150;
        setScrolled(isScrolled);
      };

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [showImmediately]);

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const menuVars = {
    initial: {
      x: "100%", // Start from off-screen right
    },
    animate: {
      x: 0, // Move to original position
      transition: {
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      x: "100%", // Slide out to the right
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <div
        className={`fixed w-full z-20 top-0 ${
          scrolled ? "" : "flex"
        } justify-between`}
        style={{
          transition: showImmediately ? "none" : "transform 0.3s ease",
          transform: showImmediately
            ? "none"
            : `translateY(${scrolled ? "0" : "-100%"})`,
          backgroundColor: "white",
          // boxShadow: scrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
        }}>
        <div className="md:container  md:mx-auto px-8 py-4 justify-between flex items-center">
          <div className="hidden md:flex">
            <Link to="/">
              <LogoNoTextIcon color={"black"} width={"115"} />
            </Link>
          </div>
          <motion.div
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: showLogo ? 1 : 1, y: showLogo ? 1 : 1 }}
            transition={{ duration: 0.3 }}
            className="h-14 flex items-center md:hidden">
            {/* {showLogo && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}>
                <OrderNowButton color="black" />
              </motion.div>
            )}
            {!showLogo && ( */}
            <div className="">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}>
                <Link to="/">
                  <LogoNoTextIcon color={"black"} width={"115"} />
                </Link>
              </motion.div>
            </div>
            {/* )} */}
          </motion.div>
          {/* Wrap PizzaMenuIcon with button */}
        </div>
      </div>

      <div
        className={`fixed pointer-events-none container w-full flex py-2 px-0  transform z-40 top-0 ${
          scrolled ? "" : "flex"
        } justify-end`}
        style={{
          transition: showImmediately ? "none" : "transform 0.3s ease",
          transform: showImmediately
            ? "translate(-50%, 0)" // Center horizontally when shown
            : `translate(-50%, ${scrolled ? "0" : "-100%"})`, // Move to the top and center horizontally when hidden
          left: "50%", // Center horizontally
          // boxShadow: scrolled ? "0 2px 4px rgba(0, 0, 0, 0.1)" : "none",
        }}>
        <AnimatePresence>
          <div className="md:container md:mx-auto px-8 py-4 justify-end flex items-center">
            {showMenu && (
              <motion.div
                key="menu"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}>
                <button
                  className="focus:outline-none  pointer-events-auto"
                  onClick={toggleMenu}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3, delay: 0.5 }} // Apply delay to the fade-in effect
                  >
                    <PizzaMenuCloseIcon />
                  </motion.div>
                </button>
              </motion.div>
            )}
            {!showMenu && (
              <motion.div
                key="menu"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}>
                <button
                  className="focus:outline-none pointer-events-auto"
                  onClick={toggleMenu}>
                  <PizzaMenuIcon color="black" />
                </button>
              </motion.div>
            )}
          </div>
        </AnimatePresence>
      </div>

      {/* Render menu if showMenu is true */}
      <AnimatePresence>
        {showMenu && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="origin-right  h-full overflow-x-hidden max-w-[100vw] w-full fixed top-0 right-0  md:grid md:grid-cols-2  z-30 overflow-auto"
            onClick={toggleMenu}>
            {/* Menu content */}

            <div className=" md:px-8 pt-20 md:pt-0 flex justify-center bg-porpora md:rounded-l-full ">
              <a href="/" className=" w-1/2 md:w-auto md:flex">
                <LogoIcon color={"#F68A1F"} width={"100%"} />
              </a>
            </div>
            {/* <div className="absolute md:top-20 md:right-20 top-8 right-8 bg-porpora ">
              <button>
                <PizzaMenuCloseIcon />
              </button>
            </div> */}
            <div className="px-8 md:py-16 flex justify-start  bg-porpora min-h-[700px]">
              <div className="md:border-l-4 border-t-2 md:border-t-0 mt-6 md:mt-0 border-giallo w-full md:w-auto justify-center align-bottom">
                <div className="md:p-24 border-giallo md:h-[100%] py-8 md:py-0 flex flex-col justify-center">
                  {menuItems.map((item, index) => (
                    <Link key={index} to={item.to}>
                      <div
                        className="flex items-center relative"
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}>
                        <motion.h3
                          initial={{ x: 0 }} // Set initial position at 0
                          animate={{ x: hoveredIndex === index ? 58 : 0 }} // Move right on hover
                          exit={{ x: 0 }} // Reset position on exit
                          transition={{ duration: 0.3 }}
                          className="font-awesome text-giallo text-5xl leading-[66px] font-bold">
                          {item.text}
                        </motion.h3>
                        <AnimatePresence>
                          {hoveredIndex === index && (
                            <motion.span
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }} // Animate only opacity
                              exit={{ opacity: 0 }}
                              transition={{ duration: 0.3 }}
                              className="absolute  font-awesome-italic italic text-giallo text-5xl leading-[66px] font-bold hidden md:flex">
                              go
                            </motion.span>
                          )}
                        </AnimatePresence>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className="absolute  container w-full cursor-none flex z-50 my-2 justify-between md:justify-end top-0 left-1/2 transform -translate-x-1/2"
        style={{
          paddingTop: scrolled ? "" : "50px",
          visibility: scrolled ? "hidden" : "",
        }}>
        <div>
          <motion.div
            className=" md:hidden"
            initial={{ opacity: 1 }}
            animate={{ opacity: showMenu ? 0 : 1 }}
            transition={{ duration: 0.5 }}
            style={{
              visibility: showImmediately
                ? "hidden"
                : showMenu
                ? "hidden"
                : "visible",
            }}>
            <OrderNowButton />
          </motion.div>
        </div>

        <AnimatePresence>
          {showMenu && (
            <motion.div
              className=" pb-12 w-full flex justify-end cursor-auto"
              key="menu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}>
              <button className="focus:outline-none" onClick={toggleMenu}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3, delay: 0.5 }} // Apply delay to the fade-in effect
                >
                  <PizzaMenuCloseIcon />
                </motion.div>
              </button>
            </motion.div>
          )}
          {!showMenu && (
            <motion.div
              ref={ref}
              key="menu"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}>
              <button className="focus:outline-none" onClick={toggleMenu}>
                <PizzaMenuIcon />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
});

export default NavBar;
