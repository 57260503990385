const QualityIcon = () => {
  return (
    <svg
      width="50"
      height="47"
      viewBox="0 0 50 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M46.0111 14.4753C47.2275 14.5723 48.3078 20.5529 45.4542 19.9235C43.735 19.5289 44.3665 14.3358 46.0111 14.4753Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.98796 14.4753C2.77151 14.5723 1.69123 20.5529 4.54485 19.9235C6.26403 19.5289 5.6325 14.3358 3.98796 14.4753Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.3258 35.0453C41.3423 32.4947 42.9169 29.7331 44.019 26.8144"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6732 35.0453C8.6567 32.4947 7.08214 29.7331 5.98003 26.8144"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.0276 20.3848C46.006 20.8995 45.4818 21.1681 45.0773 20.9151C44.0601 20.2989 43.5393 19.3416 42.6849 17.2002C41.8396 15.3162 46.1009 16.7283 46.0299 20.3848L46.0276 20.3848Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.97245 20.3848C3.99395 20.8995 4.51823 21.1681 4.92274 20.9151C5.93988 20.2989 6.46075 19.3416 7.31513 17.2002C8.16037 15.3162 3.89906 16.7283 3.97011 20.3848L3.97245 20.3848Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.0211 20.5735C44.9013 21.053 45.2916 21.5377 45.7479 21.4959C46.8616 21.3776 47.7394 20.7397 48.8665 18.7381C49.7688 16.9094 45.7797 17.2334 45.0211 20.5735Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.97794 20.5735C5.09768 21.053 4.70741 21.5377 4.25117 21.4959C3.13747 21.3776 2.2596 20.7397 1.13256 18.7381C0.230252 16.9094 4.21936 17.2334 4.97794 20.5735Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M45.5113 23.1963C45.4407 23.7015 44.8884 23.9373 44.4979 23.6679C43.5229 23.0074 43.0816 22.0361 42.4051 19.8855C41.7119 17.9899 45.9192 19.6078 45.5113 23.1963Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.48866 23.1963C4.55929 23.7015 5.11162 23.9373 5.50208 23.6679C6.47706 23.0074 6.91838 22.0361 7.59494 19.8855C8.28811 17.9899 4.08079 19.6078 4.48866 23.1963Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.474 23.3265C44.3099 23.7919 44.6603 24.2882 45.1259 24.2722C46.2653 24.2171 47.2133 23.633 48.5461 21.7322C49.6355 19.9879 45.5437 20.0849 44.474 23.3265Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.525 23.3265C5.68917 23.7919 5.33868 24.2882 4.87311 24.2722C3.73373 24.2171 2.78573 23.633 1.4529 21.7322C0.363506 19.9879 4.45536 20.0849 5.525 23.3265Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M44.7315 25.9332C44.6141 26.4267 44.0314 26.6298 43.6643 26.344C42.7385 25.6437 42.3814 24.6632 41.8874 22.5104C41.3533 20.6079 45.4762 22.427 44.7315 25.9332Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.26849 25.9332C5.3859 26.4267 5.96861 26.6298 6.33565 26.344C7.26148 25.6437 7.61856 24.6632 8.11265 22.5104C8.64672 20.6079 4.5238 22.427 5.26849 25.9332Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.6713 26.007C43.4627 26.456 43.7711 26.964 44.2437 26.9714C45.4017 26.9771 46.4128 26.4516 47.9421 24.6539C49.2092 23.0009 45.0474 22.8686 43.6713 26.0047L43.6713 26.007Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.32768 26.007C6.53629 26.456 6.22792 26.964 5.75535 26.9714C4.59731 26.9771 3.58619 26.4516 2.05693 24.6539C0.789818 23.0009 4.95165 22.8686 6.32768 26.0047L6.32768 26.007Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.6909 28.5876C43.5267 29.0671 42.9207 29.2373 42.5747 28.9352C41.705 28.1975 41.4345 27.2124 41.1276 25.0668C40.7549 23.1645 44.7701 25.1729 43.6909 28.5876Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.30907 28.5876C6.47326 29.0671 7.07933 29.2373 7.42531 28.9352C8.29496 28.1975 8.56547 27.2124 8.87241 25.0668C9.24506 23.1645 5.22992 25.1729 6.30907 28.5876Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.6122 28.6052C42.3615 29.0377 42.623 29.5527 43.1003 29.5858C44.2676 29.65 45.3372 29.1854 47.0535 27.4978C48.4936 25.941 44.2876 25.5863 42.6122 28.6052Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.38782 28.6052C7.63853 29.0377 7.37696 29.5527 6.89973 29.5858C5.73238 29.65 4.66283 29.1854 2.94651 27.4978C1.50636 25.941 5.71245 25.5863 7.38782 28.6052Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M42.3895 31.1482C42.1785 31.6112 41.5514 31.7487 41.2335 31.4349C40.4271 30.6645 40.2478 29.6795 40.1304 27.5458C39.9262 25.6529 43.8008 27.8391 42.3895 31.1482Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.60952 31.1482C7.82048 31.6112 8.44758 31.7487 8.76548 31.4349C9.57192 30.6645 9.7512 29.6795 9.86865 27.5458C10.0729 25.6529 6.19825 27.8391 7.60952 31.1482Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M41.304 31.1097C41.0112 31.5235 41.2259 32.0454 41.7032 32.102C42.8728 32.2246 43.9938 31.8209 45.8878 30.2481C47.4893 28.792 43.2717 28.2198 41.304 31.112L41.304 31.1097Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.69506 31.1097C8.98785 31.5235 8.77308 32.0454 8.29587 32.102C7.12624 32.2246 6.00527 31.8209 4.11125 30.2481C2.50976 28.792 6.72736 28.2198 8.69506 31.112L8.69506 31.1097Z"
        fill="#5C1611"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.2352 32.7131L10.668 41.9269L16.8561 40.5053L19.4823 46.2643L24.5554 36.0299"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M25.6367 35.8454L30.7957 46.2641L33.4309 40.5051L39.61 41.9268L35.1389 32.8972"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M26.8688 10.8776C28.4136 9.62799 30.7399 10.3796 31.2578 12.2992C31.594 13.5488 32.7662 14.4 34.0657 14.3275C36.0557 14.2189 37.4914 16.1929 36.7736 18.0491C36.301 19.2625 36.7554 20.6298 37.8458 21.3361C39.5178 22.4136 39.5178 24.8585 37.8458 25.936C36.7554 26.6423 36.3101 28.0096 36.7736 29.223C37.4914 31.0793 36.0557 33.0533 34.0657 32.9446C32.7662 32.8722 31.594 33.7233 31.2578 34.9729C30.749 36.8926 28.4136 37.6442 26.8688 36.3946C25.8602 35.5796 24.4154 35.5796 23.4067 36.3946C21.862 37.6442 19.5357 36.8926 19.0178 34.9729C18.6816 33.7233 17.5093 32.8722 16.2099 32.9446C14.2199 33.0533 12.7842 31.0793 13.502 29.223C13.9745 28.0096 13.5202 26.6423 12.4298 25.936C10.7578 24.8585 10.7578 22.4136 12.4298 21.3361C13.5202 20.6298 13.9655 19.2625 13.502 18.0491C12.7842 16.1929 14.2199 14.2189 16.2099 14.3275C17.5093 14.4 18.6816 13.5488 19.0178 12.2992C19.5266 10.3796 21.862 9.62799 23.4067 10.8776C24.4154 11.6925 25.8602 11.6925 26.8688 10.8776Z"
        fill="#5C1611"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M25.1421 32.5189C30.0703 32.5189 34.0654 28.5378 34.0654 23.6269C34.0654 18.716 30.0703 14.7349 25.1421 14.7349C20.2139 14.7349 16.2188 18.716 16.2188 23.6269C16.2188 28.5378 20.2139 32.5189 25.1421 32.5189Z"
        fill="#5C1611"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M25.1416 32.5189V14.7349"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M16.2188 23.6267H34.0472"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M18.8271 17.3335L31.4488 29.9109"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M18.8271 29.9109L31.4398 17.3425"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M25.1436 0.73584L26.125 2.72794L28.324 3.04486L26.7338 4.59327L27.1064 6.77552L25.1436 5.74325L23.1717 6.77552L23.5443 4.59327L21.9541 3.04486L24.1531 2.72794L25.1436 0.73584Z"
        fill="#5C1611"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M14.1297 5.68896L14.7749 6.99288L16.2197 7.20115L15.1747 8.21531L15.4201 9.646L14.1297 8.96687L12.8394 9.646L13.0847 8.21531L12.0488 7.20115L13.4846 6.99288L14.1297 5.68896Z"
        fill="#5C1611"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M36.1463 5.68896L36.7915 6.99288L38.2363 7.20115L37.1913 8.21531L37.4367 9.646L36.1463 8.96687L34.856 9.646L35.1104 8.21531L34.0654 7.20115L35.5012 6.99288L36.1463 5.68896Z"
        fill="#5C1611"
        stroke="white"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default QualityIcon;
