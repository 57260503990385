import React from "react";

const NextButtonIcon = ({ color, inactive }) => {
  const opacity = inactive ? "0.5" : "1";

  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1.00012"
        width="50"
        height="50"
        rx="25"
        stroke={color}
        strokeWidth="2"
        opacity={opacity}
      />
      <path
        d="M32 26.0001L32.7071 26.7072L33.4142 26.0001L32.7071 25.293L32 26.0001ZM21.2929 16.7072L31.2929 26.7072L32.7071 25.293L22.7071 15.293L21.2929 16.7072ZM31.2929 25.293L21.2929 35.293L22.7071 36.7072L32.7071 26.7072L31.2929 25.293Z"
        fill={color}
        opacity={opacity}
      />
    </svg>
  );
};

export default NextButtonIcon;
