const PizzaMenuCloseIcon = ({ color }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="46"
        height="46"
        rx="23"
        stroke="#F68A1F"
        stroke-width="2"
      />
      <line
        x1="7.9071"
        y1="8.22612"
        x2="39.7387"
        y2="40.0577"
        stroke="#F68A1F"
        stroke-width="2"
      />
      <line
        x1="8.26125"
        y1="40.0566"
        x2="40.0928"
        y2="8.225"
        stroke="#F68A1F"
        stroke-width="2"
      />
    </svg>
  );
};

export default PizzaMenuCloseIcon;
