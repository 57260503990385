const FacebookIcon = () => {
  return (
    <svg
      width="18"
      height="32"
      viewBox="0 0 18 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0963 18.6036V32H5.35979V18.6036H0.594055V13.1716H5.35979V11.1953C5.35979 3.85799 8.21703 0 14.2625 0C16.1158 0 16.5791 0.319527 17.5941 0.579882V5.95266C16.4578 5.73965 16.1379 5.6213 14.9575 5.6213C13.5564 5.6213 12.8063 6.04734 12.1223 6.88757C11.4383 7.72781 11.0963 9.18343 11.0963 11.2663V13.1834H17.5941L15.851 18.6154H11.0963V18.6036Z"
        fill="#302713"
      />
    </svg>
  );
};
export default FacebookIcon;
