import React, { useRef } from "react";
import LogoNoTextIcon from "./icons/LogoNoTextIcon";
import HeroImage from "../images/HeroImage.jpg";
import OrderNowButton from "./OrderNowButton";
import Cursor from "./stickyCursor/Cursor";

const HeroBanner = ({ stickyElement }) => {
  const bannerRef = useRef(null);

  return (
    <div
      ref={bannerRef}
      className="relative bg-cover bg-no-repeat bg-center h-[90vh] md:h-screen"
      style={{ backgroundImage: `url(${HeroImage})` }}>
      <Cursor stickyElement={stickyElement} heroBannerRef={bannerRef} />
      <div className=" flex flex-col justify-center md:justify-start items-center md:items-start md:mt-16 w-full absolute h-[90vh]">
        <div
          className="md:container mx-auto border-b-2 border-white md:border-0"
          data-aos="fade-in">
          <LogoNoTextIcon color={"white"} width={"236"} />
        </div>
        <div className="mx-auto md:justify-center md:align-middle flex flex-col md:gap-8 md:h-[70vh]">
          <h2
            className="text-white text-center text-4xl md:text-9xl leading-9 mt-4 font-awesome font-bold"
            data-aos="fade-in"
            data-aos-delay="200">
            ARTISANAL PIZZERIA
          </h2>
        </div>
      </div>
      <div
        className="h-full md:container flex items-end justify-center md:justify-between md:mx-auto md:mb-16"
        data-aos="fade-up"
        data-aos-delay="500">
        <div className="flex flex-col items-center">
          <h2 className="mx-auto mb-8 text-white md:mx-0 text-3xl md:text-4xl md:mb-12 font-awesome-italic italic">
            Scroll for Crust & Craft
          </h2>
        </div>
        <div className="hidden md:flex mb-8 md:mb-10 z-10 cursor-none">
          <OrderNowButton />
        </div>
      </div>
    </div>
  );
};

export default HeroBanner;
