import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import LogoIcon from "../components/icons/LogoIcon";

export default function Contacts() {
  return (
    <div className="overflow-x-hidden">
      <NavBar showImmediately={true} />
      <div className="md:container md:mx-auto mt-[92px] md:mb-8 mx-4 ">
        <div className="border-b-2 md:flex-row md:justify-between border-legno border-t-2 flex gap-2 flex-col py-6 md:items-center">
          <div className="flex flex-col gap-2">
            <h1
              className="text-5xl md:text-6xl font-awesome font-bold mx-4 md:mx-0"
              data-aos="fade-in">
              CONTACTS
            </h1>
            <h2
              className="font-awesome-italic italic text-xl mx-4 md:mx-0 md:text-2xl md:text-porpora "
              data-aos="fade-in"
              data-aos-delay="300">
              Connect with Us
            </h2>
          </div>
        </div>
      </div>
      <div className="md:grid md:grid-cols-2 md:mb-16 md:container md:mx-auto">
        <div className="flex justify-center mx-4 md:order-2 md:mx-0 md:my-16">
          <div
            className="w-full h-full border-0"
            data-aos="fade-in"
            data-aos-delay="200">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d229928.6266650257!2d-80.39426299685496!3d25.782623832633785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b0a20ec8c111%3A0xff96f271ddad4f65!2sMiami%2C%20FL%2C%20USA!5e0!3m2!1sen!2sth!4v1713850683655!5m2!1sen!2sth"
              title="Google Maps"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              style={{
                width: "100%",
                height: "400px", // Default height for mobile
              }}
              // Responsive height based on screen size
            ></iframe>
          </div>
        </div>
        <div className="mx-8 my-9 flex flex-col gap-6 md:mx-0">
          <div className="flex flex-col gap-1.5" data-aos="fade-right">
            <h2 className="font-awesome font-bold text-legno text-2xl leading-8">
              Marc's Artisanal Pizzeria
            </h2>
            <p className="font-awesome font-extralight text-xl leading-6 ">
              1234 Miami Ave, Miami, FL 33130
            </p>
          </div>
          <div
            className="flex flex-col gap-1.5 font-awesome font-extralight text-xl"
            data-aos="fade-right"
            data-aos-delay="200">
            <h3 className="font-awesome text-2xl font-bold">
              Hours of Operation:
            </h3>
            <span>Wed–Thu: 4pm–10pm</span>
            <span>Fri: 4pm–11pm</span>
            <span>Sat–Sun: 12pm–11pm, 12pm–10pm</span>
            <span>Closed Mon & Tue</span>
          </div>

          <div
            className="flex flex-col gap-1.5 font-awesome font-extralight text-base"
            data-aos="fade-right"
            data-aos-delay="600">
            <h3 className="font-awesome text-2xl font-bold">
              General Inquiries:
            </h3>
            <span className="text-xl">
              <a href="mailto:info@marcsartisanalpizzeria.com">
                info@marcsartisanalpizzeria.com
              </a>
            </span>

            <span className="text-xl">
              <a href="tel:7865585784">(786) 558-5784</a>
            </span>
          </div>
          <div
            className="flex flex-col gap-1.5 font-awesome font-extralight text-base"
            data-aos="fade-right"
            data-aos-delay="800">
            <h3 className="font-awesome text-2xl font-bold">
              Catering and Events:
            </h3>
            <span className="text-xl">
              <a href="mailto:events@marcsartisanalpizzeria.com">
                events@marcsartisanalpizzeria.com
              </a>
            </span>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
