// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: black;
  opacity: 1;
}

/* target all bullets */
.swiper-pagination-bullet {
  background-color: transparent;
  border-width: 1px;
  width: 20px;
  height: 20px;
  border-color: black;
  opacity: 1;
}
@media (max-width: 767px) {
  .swiper-horizontal {
    overflow: visible;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA,6BAA6B;AAC7B;EACE,uBAAuB;EACvB,UAAU;AACZ;;AAEA,uBAAuB;AACvB;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".swiper-slide {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n/* target the active bullet */\nspan.swiper-pagination-bullet.swiper-pagination-bullet-active {\n  background-color: black;\n  opacity: 1;\n}\n\n/* target all bullets */\n.swiper-pagination-bullet {\n  background-color: transparent;\n  border-width: 1px;\n  width: 20px;\n  height: 20px;\n  border-color: black;\n  opacity: 1;\n}\n@media (max-width: 767px) {\n  .swiper-horizontal {\n    overflow: visible;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
