import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./mystyles.css";

import HeroImage from "../images/HeroImage.jpg";

// import required modules
import { Pagination } from "swiper/modules";

const Carousel = () => {
  return (
    <div>
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        grabCursor={true}
        modules={[Pagination]}
        className="myCarouselSwiper overflow-visible">
        <SwiperSlide>
          <img
            src={HeroImage}
            alt="Pizza "
            className="object-cover h-[700px] md:h-[857px] md:w-full"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={HeroImage}
            alt="Pizza "
            className="object-cover h-[700px] md:h-[857px] md:w-full"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            src={HeroImage}
            alt="Pizza "
            className="object-cover h-[700px] md:h-[857px] md:w-full"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default Carousel;
