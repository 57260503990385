const DedicationIcon = () => {
  return (
    <svg
      width="50"
      height="49"
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.86328 47.7769H44.5352"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M13.5117 41.6855H36.8848"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M20.7166 41.6856C20.7166 41.6856 21.6524 38.0074 20.3125 36.7586C20.3125 36.7586 22.2373 36.6071 23.2157 37.814C23.2157 37.814 21.4982 33.4983 24.4652 32.1138C24.4652 32.1138 24.3748 33.9007 26.39 35.1285C28.4053 36.3563 27.7353 38.5821 27.7353 38.5821C27.7353 38.5821 29.7718 36.1682 31.3616 36.6541C31.3616 36.6541 29.2773 39.0366 30.0748 41.6804"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M24.1367 41.6857C24.1367 41.6857 25.1689 40.2841 25.3143 38.6401C25.3143 38.6401 26.8262 40.1998 26.676 41.6857"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M11.3867 47.7768V38.412C11.3867 30.6196 17.5813 24.2822 25.1981 24.2822C32.8149 24.2822 39.0095 30.6196 39.0095 38.412V47.7768"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M16.9121 41.6855V38.3988C16.9121 33.4849 20.6214 29.5032 25.1989 29.5032C29.7765 29.5032 33.4858 33.4849 33.4858 38.3988V41.6855"
        stroke="white"
        stroke-linejoin="round"
      />
      <path
        d="M5.86328 1.2229H44.5352"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M11.3867 1.2229V10.4142C11.3867 18.0624 17.5813 24.2824 25.1981 24.2824C32.8149 24.2824 39.0095 18.0624 39.0095 10.4142V1.2229"
        stroke="white"
        stroke-linejoin="round"
      />
      <path d="M25.1992 24.5L25.1992 1.4917" stroke="white" />
      <path
        d="M25.1976 16.5661C22.7877 16.5661 20.834 14.5236 20.834 12.0041C20.834 9.48461 22.7877 7.44214 25.1976 7.44214"
        stroke="white"
      />
      <path
        d="M25.5957 16.5661C28.0057 16.5661 29.9593 14.5236 29.9593 12.0041C29.9593 9.48461 28.0057 7.44214 25.5957 7.44214"
        stroke="white"
      />
      <path
        d="M32.7363 3.87183L32.7363 7.04538"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M33.5293 14.5825L33.5293 16.1693"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M31.1484 18.1528L31.1484 18.9462"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M34.3223 9.42554L34.3223 11.8057"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M31.1484 5.4585L34.322 5.4585"
        stroke="white"
        stroke-linecap="round"
      />
      <path d="M32.7363 15.376H34.3231" stroke="white" stroke-linecap="round" />
      <path d="M30.752 18.5496H31.5453" stroke="white" stroke-linecap="round" />
      <path d="M33.1328 10.6155H35.513" stroke="white" stroke-linecap="round" />
      <line
        x1="22.7721"
        y1="7.87485"
        x2="20.3237"
        y2="3.63409"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="21.106"
        y1="9.90884"
        x2="16.8653"
        y2="7.46044"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="20.334"
        y1="12.3057"
        x2="15.7803"
        y2="12.3057"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="21.606"
        y1="14.9652"
        x2="17.3653"
        y2="17.4136"
        stroke="white"
        stroke-linecap="round"
      />
      <line
        x1="23.6381"
        y1="16.6332"
        x2="21.1897"
        y2="20.874"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M12.9008 25.095L5.16529 18.1528M10.719 27.2768L5.16529 25.095M9.52893 30.252L1 29.4586"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M37.0992 25.095L44.8347 18.1528M39.281 27.2768L44.8347 25.095M40.4711 30.252L49 29.4586"
        stroke="white"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default DedicationIcon;
