import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import "./mystyles.css";
import "swiper/css/navigation";
import PreviousButtonIcon from "./icons/PreviousButtonIcon";
import NextButtonIcon from "./icons/NextButtonIcon";

const MenuCarousel = ({ category, menuItems, id }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.on("slideChange", () => {
        setIsBeginning(swiperRef.current.swiper.isBeginning);
        setIsEnd(swiperRef.current.swiper.isEnd);
      });
    }
  }, []);

  const goPrev = () => {
    if (swiperRef.current && !isBeginning) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const goNext = () => {
    if (swiperRef.current && !isEnd) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className="mb-12 relative container">
      <div className="flex justify-between items-center">
        <h2 className="font-awesome font-bold text-2xl md:text-3xl mx-8 my-6 md:mx-0">
          {category}
        </h2>
        <div>
          <div
            className="gap-2 hidden md:flex"
            data-aos="fade-in"
            data-aos-delay="500">
            <div
              onClick={goPrev}
              style={{
                cursor: isBeginning ? "default" : "pointer",
                userSelect: "none",
              }}>
              <PreviousButtonIcon color="#302713" inactive={isBeginning} />
            </div>
            <div
              onClick={goNext}
              style={{
                cursor: isEnd ? "default" : "pointer",
                userSelect: "none",
              }}>
              <NextButtonIcon color="#302713" inactive={isEnd} />
            </div>
          </div>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        grabCursor={true}
        modules={[Navigation]}
        slidesPerView={1}
        breakpoints={{
          1440: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: -50,
          },
        }}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        class="mySwiperMenu">
        {menuItems.map((menuItem, index) => (
          <SwiperSlide key={index}>
            <div
              className="border-black border-2 p-6 mx-8 md:mx-0  md:h-[350px] md:w-[350px] bg-white flex flex-col w-full"
              data-aos="fade-in"
              data-aos-delay={index * 200}>
              <div className="flex justify-between mb-2">
                <h3 className="text-2xl font-awesome font-bold">
                  {menuItem.name}
                </h3>
                <h4 className="text-2xl font-awesome-italic italic">
                  {menuItem.price}
                </h4>
              </div>
              <h4 className="text-sm  font-awesome-italic italic leading-5 mb-6">
                {menuItem.description}
              </h4>
              <img
                src={menuItem.image}
                alt={menuItem.name}
                className="md:h-[180px] md:object-cover"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default MenuCarousel;
