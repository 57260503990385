const QualityCardIcon = () => {
  return (
    <svg
      width="137"
      height="177"
      viewBox="0 0 137 177"
      fill="white"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M124.641 44.0111C127.942 44.2742 130.874 60.503 123.13 58.7949C118.465 57.7243 120.179 43.6326 124.641 44.0111Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.6086 44.0111C7.30772 44.2742 4.37634 60.503 12.1197 58.7949C16.7848 57.7243 15.0711 43.6326 10.6086 44.0111Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M106.5 99.828C111.972 92.9069 116.244 85.4133 119.235 77.4933"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.75 99.828C23.2781 92.9069 19.0055 85.4133 16.0149 77.4933"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M124.684 60.0468C124.626 61.4434 123.203 62.1722 122.106 61.4856C119.346 59.8136 117.932 57.2159 115.614 51.4053C113.32 46.2929 124.883 50.1247 124.691 60.0468L124.684 60.0468Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5658 60.0468C10.6242 61.4434 12.0468 62.1722 13.1445 61.4856C15.9045 59.8136 17.3179 57.2159 19.6363 51.4053C21.9299 46.2929 10.3667 50.1247 10.5595 60.0468L10.5658 60.0468Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M121.955 60.5585C121.63 61.8597 122.689 63.1747 123.927 63.0615C126.949 62.7404 129.332 61.0094 132.39 55.5779C134.838 50.6158 124.014 51.495 121.955 60.5585Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2948 60.5585C13.6197 61.8597 12.5607 63.1747 11.3227 63.0615C8.30061 62.7404 5.91849 61.0094 2.86022 55.5779C0.411789 50.6158 11.2363 51.495 13.2948 60.5585Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M123.282 67.6761C123.091 69.0471 121.592 69.687 120.532 68.956C117.887 67.1635 116.689 64.5279 114.853 58.6922C112.972 53.5485 124.389 57.9388 123.282 67.6761Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9677 67.6761C12.1594 69.0471 13.6581 69.687 14.7177 68.956C17.3633 67.1635 18.5608 64.5279 20.3967 58.6922C22.2776 53.5485 10.861 57.9388 11.9677 67.6761Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M120.469 68.0291C120.023 69.292 120.974 70.6386 122.238 70.5953C125.329 70.4457 127.902 68.8609 131.518 63.7029C134.475 58.9697 123.371 59.2329 120.469 68.0291Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7813 68.0291C15.2268 69.292 14.2758 70.6386 13.0124 70.5953C9.92069 70.4457 7.34825 68.8609 3.73157 63.7029C0.775482 58.9697 11.8788 59.2329 14.7813 68.0291Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M121.167 75.1018C120.848 76.441 119.267 76.9919 118.271 76.2165C115.759 74.3162 114.79 71.6554 113.449 65.8139C112 60.6515 123.188 65.5875 121.167 75.1018Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0831 75.1018C14.4017 76.441 15.9829 76.9919 16.9789 76.2165C19.4911 74.3162 20.4601 71.6554 21.8008 65.8139C23.25 60.6515 12.0623 65.5875 14.0831 75.1018Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.292 75.3023C117.726 76.5207 118.562 77.899 119.845 77.9192C122.987 77.9346 125.731 76.5087 129.88 71.6305C133.319 67.1453 122.026 66.7862 118.292 75.296L118.292 75.3023Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9584 75.3023C17.5244 76.5207 16.6877 77.899 15.4053 77.9192C12.263 77.9346 9.51927 76.5087 5.36958 71.6305C1.93124 67.1453 13.2245 66.7862 16.9584 75.296L16.9584 75.3023Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M118.343 82.3045C117.898 83.6055 116.253 84.0675 115.314 83.2478C112.955 81.246 112.22 78.5727 111.388 72.7506C110.376 67.5886 121.272 73.0386 118.343 82.3045Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9067 82.3045C17.3522 83.6055 18.9968 84.0675 19.9357 83.2478C22.2955 81.246 23.0295 78.5727 23.8624 72.7506C24.8736 67.5886 13.9784 73.0386 16.9067 82.3045Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M115.416 82.3526C114.736 83.5265 115.445 84.9237 116.74 85.0137C119.908 85.1879 122.81 83.9271 127.468 79.3478C131.375 75.1233 119.962 74.161 115.416 82.3526Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.8341 82.3526C20.5144 83.5265 19.8046 84.9237 18.5097 85.0137C15.342 85.1879 12.4398 83.9271 7.78249 79.3478C3.87461 75.1233 15.2879 74.161 19.8341 82.3526Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M114.813 89.2528C114.241 90.5093 112.539 90.8823 111.676 90.0309C109.488 87.9404 109.002 85.2674 108.683 79.4775C108.129 74.3413 118.643 80.2734 114.813 89.2528Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.4368 89.2528C21.0093 90.5093 22.7109 90.8823 23.5735 90.0309C25.7619 87.9404 26.2483 85.2674 26.567 79.4775C27.1212 74.3413 16.6073 80.2734 20.4368 89.2528Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M111.868 89.1487C111.074 90.2716 111.657 91.6878 112.952 91.8413C116.126 92.1742 119.167 91.0786 124.307 86.8107C128.652 82.8596 117.208 81.3069 111.868 89.1551L111.868 89.1487Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.3815 89.1487C24.176 90.2716 23.5932 91.6878 22.2983 91.8413C19.1245 92.1742 16.0827 91.0786 10.9432 86.8107C6.59755 82.8596 18.0421 81.3069 23.3815 89.1551L23.3815 89.1487Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M52.6502 130.271L45.5242 114.644L28.7324 118.502L51.8858 71.7925L75.8037 83.562L52.6502 130.271Z"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M83.3485 130.271L90.4992 114.644L107.266 118.502L84.1375 71.7925L60.2197 83.562L83.3485 130.271Z"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M72.6968 34.248C76.8886 30.8572 83.201 32.8966 84.6064 38.1057C85.5188 41.4965 88.6996 43.8061 92.2256 43.6096C97.6256 43.3147 101.522 48.6712 99.5736 53.7083C98.2914 57.0008 99.5242 60.711 102.483 62.6275C107.02 65.5515 107.02 72.1856 102.483 75.1096C99.5242 77.0261 98.316 80.7363 99.5736 84.0288C101.522 89.0659 97.6256 94.4224 92.2256 94.1275C88.6996 93.9309 85.5188 96.2406 84.6064 99.6314C83.2256 104.84 76.8886 106.88 72.6968 103.489C69.9599 101.278 66.0393 101.278 63.3023 103.489C59.1105 106.88 52.7982 104.84 51.3927 99.6314C50.4804 96.2406 47.2996 93.9309 43.7736 94.1275C38.3736 94.4224 34.4777 89.0659 36.4256 84.0288C37.7078 80.7363 36.4749 77.0261 33.516 75.1096C28.979 72.1856 28.979 65.5515 33.516 62.6275C36.4749 60.711 37.6831 57.0008 36.4256 53.7083C34.4777 48.6712 38.3736 43.3147 43.7736 43.6096C47.2996 43.8061 50.4804 41.4965 51.3927 38.1057C52.7736 32.8966 59.1105 30.8572 63.3023 34.248C66.0393 36.4594 69.9599 36.4594 72.6968 34.248Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M68.0125 92.9727C81.3854 92.9727 92.2262 82.17 92.2262 68.844C92.2262 55.5181 81.3854 44.7153 68.0125 44.7153C54.6397 44.7153 43.7988 55.5181 43.7988 68.844C43.7988 82.17 54.6397 92.9727 68.0125 92.9727Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M68.0127 92.9727V44.7153"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M43.7988 68.844H92.1769"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M50.876 51.7671L85.1253 85.8962"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M50.876 85.8963L85.1006 51.7917"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M68.0122 6.72852L70.6752 12.1341L76.6424 12.9941L72.3273 17.1958L73.3382 23.1174L68.0122 20.3163L62.6615 23.1174L63.6725 17.1958L59.3574 12.9941L65.3245 12.1341L68.0122 6.72852Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M38.127 20.1689L39.8777 23.7072L43.7983 24.2723L40.9627 27.0243L41.6284 30.9065L38.127 29.0636L34.6257 30.9065L35.2914 27.0243L32.4805 24.2723L36.3764 23.7072L38.127 20.1689Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M97.8722 20.1689L99.6228 23.7072L103.543 24.2723L100.708 27.0243L101.374 30.9065L97.8722 29.0636L94.3708 30.9065L95.0612 27.0243L92.2256 24.2723L96.1215 23.7072L97.8722 20.1689Z"
        fill="white"
        stroke="#302713"
        stroke-width="2"
        stroke-linejoin="round"
      />
      <path
        d="M8.35703 176.86C4.10903 176.86 0.917031 172.588 0.917031 166.9C0.917031 161.212 4.10903 156.94 8.35703 156.94C12.605 156.94 15.797 161.212 15.797 166.9C15.797 169.876 14.933 172.468 13.493 174.244L15.605 176.5H12.677L11.933 175.708C10.877 176.452 9.67703 176.86 8.35703 176.86ZM3.31703 166.9C3.31703 171.58 5.47703 174.748 8.35703 174.748C9.10103 174.748 9.82103 174.532 10.445 174.124L7.39703 170.908H10.325L11.933 172.612C12.845 171.244 13.397 169.252 13.397 166.9C13.397 162.22 11.237 159.052 8.35703 159.052C5.47703 159.052 3.31703 162.22 3.31703 166.9ZM31.3001 176.86C27.5081 176.86 24.6761 173.836 24.6761 169.78V157.3H27.0281V169.78C27.0281 172.564 28.7321 174.748 31.3001 174.748C33.8681 174.748 35.5721 172.564 35.5721 169.78V157.3H37.9241V169.78C37.9241 173.836 35.0921 176.86 31.3001 176.86ZM45.3845 176.5L52.5845 157.3H55.2725L62.4725 176.5H59.8805L57.6485 170.236H50.2085L47.9765 176.5H45.3845ZM50.9285 168.172H56.9285L53.9285 159.7L50.9285 168.172ZM70.6427 176.5V157.3H72.9947V174.436H82.0187V176.5H70.6427ZM90.1895 176.5V157.3H92.5415V176.5H90.1895ZM106.649 176.5V159.364H100.697V157.3H114.953V159.364H109.001V176.5H106.649ZM127.943 176.5V170.02L121.439 157.3H124.127L129.119 167.572L134.111 157.3H136.799L130.295 170.02V176.5H127.943Z"
        fill="#302713"
      />
    </svg>
  );
};

export default QualityCardIcon;
