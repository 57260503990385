import FollowUsButton from "./FollowUsButton";

// Importing only the first 6 Instagram images
import InstagramImage1 from "../images/InstagramImage1.jpg";
import InstagramImage2 from "../images/InstagramImage2.jpg";
import InstagramImage3 from "../images/InstagramImage3.jpg";
import InstagramImage4 from "../images/InstagramImage4.jpg";
import InstagramImage5 from "../images/InstagramImage5.jpg";
import InstagramImage6 from "../images/InstagramImage6.jpg";

const InstagramGrid = () => {
  return (
    <>
      <div className="md:mt-32" data-aos="fade-up">
        <h2
          className="text-5xl leading-[4rem] container text-center font-awesome md:text-6xl font-bold md:mb-12 md:leading-[80px]"
          data-aos="fade-up">
          Follow Our Flavorful Journey on <br className="hidden md:flex" />
          Instagram
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 container gap-2 md:gap-8 my-8 2xl:gap-24 2xl:gap-x-0 md:my-16">
          {/* Rendering the first 6 Instagram images */}
          <img
            src={InstagramImage1}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
          <img
            src={InstagramImage2}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
          <img
            src={InstagramImage3}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
          <img
            src={InstagramImage4}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
          <img
            src={InstagramImage5}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
          <img
            src={InstagramImage6}
            alt="Instagram Grid of photos"
            className="mx-auto"
            data-aos="fade-up"
          />
        </div>
        <div
          className="flex justify-center md:mb-32 md:mt-16"
          data-aos="fade-up">
          <FollowUsButton />
        </div>
      </div>
    </>
  );
};

export default InstagramGrid;
