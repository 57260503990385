// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myCarouselSwiper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* target the active bullet */
.myCarouselSwiper
  span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}
/* .myTestimonialSwiper .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 10px) !important;
} */
.myCarouselSwiper .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, -40px) !important;
}

.myCarouselSwiper .swiper {
  overflow: visible !important;
}

/* Adjust the gap between pagination bullets */
.myCarouselSwiper .swiper-pagination-bullet {
  background-color: transparent;
  border-width: 1px;
  width: 19px;
  height: 19px;
  border-color: black !important;
  opacity: 1;
  margin: 0 8px !important ; /* Add margin to create a 16px gap between bullets */
}
.myTestimonialSwiper .swiper-pagination-bullet {
  background-color: transparent;
  border-width: 1px;
  width: 19px;
  height: 19px;
  border-color: black;
  opacity: 1;
  margin: 0 8px !important ; /* Add margin to create a 16px gap between bullets */
}
`, "",{"version":3,"sources":["webpack://./src/components/mystyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA,6BAA6B;AAC7B;;EAEE,uBAAuB;EACvB,UAAU;AACZ;AACA;;;;;GAKG;AACH;;;;EAIE,yDAAyD;AAC3D;;AAEA;EACE,4BAA4B;AAC9B;;AAEA,8CAA8C;AAC9C;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,8BAA8B;EAC9B,UAAU;EACV,yBAAyB,EAAE,oDAAoD;AACjF;AACA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,yBAAyB,EAAE,oDAAoD;AACjF","sourcesContent":[".myCarouselSwiper .swiper-slide {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n/* target the active bullet */\n.myCarouselSwiper\n  span.swiper-pagination-bullet.swiper-pagination-bullet-active {\n  background-color: white;\n  opacity: 1;\n}\n/* .myTestimonialSwiper .swiper-pagination-fraction,\n.swiper-pagination-custom,\n.swiper-horizontal > .swiper-pagination-bullets,\n.swiper-pagination-bullets.swiper-pagination-horizontal {\n  bottom: var(--swiper-pagination-bottom, 10px) !important;\n} */\n.myCarouselSwiper .swiper-pagination-fraction,\n.swiper-pagination-custom,\n.swiper-horizontal > .swiper-pagination-bullets,\n.swiper-pagination-bullets.swiper-pagination-horizontal {\n  bottom: var(--swiper-pagination-bottom, -40px) !important;\n}\n\n.myCarouselSwiper .swiper {\n  overflow: visible !important;\n}\n\n/* Adjust the gap between pagination bullets */\n.myCarouselSwiper .swiper-pagination-bullet {\n  background-color: transparent;\n  border-width: 1px;\n  width: 19px;\n  height: 19px;\n  border-color: black !important;\n  opacity: 1;\n  margin: 0 8px !important ; /* Add margin to create a 16px gap between bullets */\n}\n.myTestimonialSwiper .swiper-pagination-bullet {\n  background-color: transparent;\n  border-width: 1px;\n  width: 19px;\n  height: 19px;\n  border-color: black;\n  opacity: 1;\n  margin: 0 8px !important ; /* Add margin to create a 16px gap between bullets */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
