import React, { useEffect, useState } from "react";
import LogoIcon from "../components/icons/LogoIcon";
const Login = ({ onLogin }) => {
  const [password, setPassword] = useState("");

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onLogin(password);
  };

  useEffect(() => {
    document.title = "Marc's Artisanal Pizzeria: Coming Soon";
  }, []);

  return (
    <div className="bg-porpora h-screen w-screen text-white flex justify-center flex-col align-middle ">
      <div className="mx-auto">
        <div className="flex justify-center mb-12">
          <div className="hidden md:flex">
            <LogoIcon color={"#F68A1F"} width={"400"} />
          </div>
          <div className="flex md:hidden">
            <LogoIcon color={"#F68A1F"} width={"200"} />
          </div>
        </div>
        <h1 className="font-awesome text-giallo text-center text-4xl md:text-[14rem]">
          COMING SOON
        </h1>
        <div className="flex justify-center mt-12">
          <form onSubmit={handleSubmit} className="flex gap-8">
            <input
              className="bg-red-950"
              type="password"
              value={password}
              onChange={handleChange}
              placeholder="Enter password"
            />

            <button type="submit">Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
