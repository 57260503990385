import Slider from "react-infinite-logo-slider";
import TextScrollIcon from "./icons/TextScrollIcon";

const TextScrollComponent = () => {
  return (
    <Slider
      width="2000px"
      toRight={false}
      duration={50}
      pauseOnHover={false}
      blurBorders={false}
      blurBoderColor={"#fff"}>
      <Slider.Slide>
        <TextScrollIcon />
      </Slider.Slide>
      <Slider.Slide>
        <TextScrollIcon />
      </Slider.Slide>
    </Slider>
  );
};

export default TextScrollComponent;
