const PizzaMenuIcon = ({ color }) => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.5"
        y="1"
        width="38"
        height="38"
        rx="19"
        stroke={color === "black" ? "black" : "white"}
        strokeWidth="2"
      />
      <line
        x1="20.5"
        y1="2"
        x2="20.5"
        y2="38"
        stroke={color === "black" ? "black" : "white"}
        strokeWidth="2"
      />
      <line
        x1="7.20711"
        y1="6.73723"
        x2="33.7334"
        y2="33.2635"
        stroke={color === "black" ? "black" : "white"}
        strokeWidth="2"
      />
      <line
        x1="2.5"
        y1="20"
        x2="38.5"
        y2="20"
        stroke={color === "black" ? "black" : "white"}
        strokeWidth="2"
      />
      <line
        x1="7.26659"
        y1="33.2626"
        x2="33.7929"
        y2="6.73632"
        stroke={color === "black" ? "black" : "white"}
        strokeWidth="2"
      />
    </svg>
  );
};

export default PizzaMenuIcon;
