import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./style.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";

const Testimonials = () => {
  return (
    <div className="relative ">
      <Swiper
        pagination={{ clickable: true }}
        grabCursor={true}
        loop={true}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
        }}
        modules={[Autoplay, Pagination]}
        className="overflow-hidden my-8 text-center rounded-full py-20 myTestimonialSwiper justify-center items-center h-[380px] w-[380px] md:h-[450px] md:w-[450px] bg-white   ">
        <SwiperSlide>
          <div className="flex ">
            <div className="flex flex-col justify-center gap-4">
              <h4 className="text-2xl text-legno font-awesome font-semibold">
                Emily R.
              </h4>
              <p className="text-porpora mx-8 text-xl font-awesome-italic italic leading-[30px]">
                "Marc's Pizzeria is a gem in Miami. The warmth of the atmosphere
                and the quality of the pizza are unmatched. Every visit feels
                like coming home to a meal made with genuine passion and the
                finest ingredients. Truly a delightful experience every time."
              </p>
              <span className="opacity-50 font-neuething-regular">
                10 February 2024
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex ">
            <div className="flex flex-col justify-center gap-4">
              <h4 className="text-2xl text-legno font-awesome font-semibold">
                Emily R.
              </h4>
              <p className="text-porpora mx-8 text-xl font-awesome-italic italic leading-[30px]">
                "Marc's Pizzeria is a gem in Miami. The warmth of the atmosphere
                and the quality of the pizza are unmatched. Every visit feels
                like coming home to a meal made with genuine passion and the
                finest ingredients. Truly a delightful experience every time."
              </p>
              <span className="opacity-50 font-neuething-regular">
                10 February 2024
              </span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="flex ">
            <div className="flex flex-col justify-center gap-4">
              <h4 className="text-2xl text-legno font-awesome font-semibold">
                Emily R.
              </h4>
              <p className="text-porpora mx-8 text-xl font-awesome-italic italic leading-[30px]">
                "Marc's Pizzeria is a gem in Miami. The warmth of the atmosphere
                and the quality of the pizza are unmatched. Every visit feels
                like coming home to a meal made with genuine passion and the
                finest ingredients. Truly a delightful experience every time."
              </p>
              <span className="opacity-50 font-neuething-regular">
                10 February 2024
              </span>
            </div>
          </div>
        </SwiperSlide>

        {/* Add more SwiperSlides as needed */}
      </Swiper>
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2">
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
};

export default Testimonials;
